import React, { useEffect } from 'react';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../../Contexts/JwtProvider';

const AdminLeagueSeasonStatsPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Admin Choices</h1>
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-md">
          <ul className="divide-y divide-gray-200">
            <li className="py-4">
              <button
                className="w-full py-2 px-4 bg-primary text-white font-semibold rounded-md hover:bg-primary-dark transition duration-300"
                onClick={() => handleNavigate('/adminregisterlss')}>
                Register League Season Stats
              </button>
            </li>
            <li className="py-4">
              <button
                className="w-full py-2 px-4 bg-primary text-white font-semibold rounded-md hover:bg-primary-dark transition duration-300"
                onClick={() => handleNavigate('/adminhandlealllss')}>
                Handle League Season Stats
              </button>
            </li>
          </ul>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminLeagueSeasonStatsPage;
