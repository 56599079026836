import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../Partials/Header/MainHeader';
import MainFooter from '../../Partials/Footer/MainFooter';
import SimpleTable, { SimpleTableProps } from '../../Partials/SimpleTable/SimpleTable';
import { FaNewspaper, FaShareAlt, FaTable, FaGlobe, FaComments, FaTrophy, FaLightbulb } from 'react-icons/fa';
import { useJwt } from '../../../Contexts/JwtProvider';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { role } = useJwt();

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Sample data for SimpleTable
  const simpleTableData: SimpleTableProps = {
    name: 'European Football League',
    numberOfTeams: 10,
    positiveAfterSeasonTeams: 4,
    negativeAfterSeasonTeams: 1,
    numberOfRelegations: 2,
    items: [
      { name: 'League Winner', tablePlace: 1 },
      { name: 'Europa League 1', tablePlace: 2 },
      { name: 'Europa League 2', tablePlace: 3 },
      { name: 'Conference League 1', tablePlace: 4 },
      { name: 'Mid Team 1', tablePlace: 5 },
      { name: 'Mid Team 2', tablePlace: 6 },
      { name: 'Mid Team 3', tablePlace: 7 },
      { name: 'Relegation Qualifier', tablePlace: 8 },
      { name: 'Relegated Team 1', tablePlace: 9 },
      { name: 'Relegated Team 2', tablePlace: 10 },
    ],
    winnerColor: '#0f1990',
    positiveColor: '#2667b0',
    negativeColor: '#c55258',
    relegationColor: '#5d191c',
    itemColor: '#000000',
  };

  const simpleTableData2: SimpleTableProps = {
    name: 'European Hockey League',
    numberOfTeams: 10,
    positiveAfterSeasonTeams: 4,
    negativeAfterSeasonTeams: 2,
    numberOfRelegations: 2,
    items: [
      { name: 'League Winner', tablePlace: 1 },
      { name: 'Playoff Team 1', tablePlace: 2 },
      { name: 'Playoff Team 2', tablePlace: 3 },
      { name: 'Playoff Team 3', tablePlace: 4 },
      { name: 'Mid Team 1', tablePlace: 5 },
      { name: 'Mid Team 2', tablePlace: 6 },
      { name: 'Relegation Qualifier 1', tablePlace: 7 },
      { name: 'Relegation Qualifier 2', tablePlace: 8 },
      { name: 'Relegated Team 1', tablePlace: 9 },
      { name: 'Relegated Team 2', tablePlace: 10 },
    ],
    winnerColor: '#0f1990',
    positiveColor: '#2667b0',
    negativeColor: '#c55258',
    relegationColor: '#5d191c',
    itemColor: '#000000',
  };

  const simpleTableData3: SimpleTableProps = {
    name: 'American Sports League',
    numberOfTeams: 10,
    positiveAfterSeasonTeams: 6,
    negativeAfterSeasonTeams: 0,
    numberOfRelegations: 0,
    items: [
      { name: 'League Winner', tablePlace: 1 },
      { name: 'Playoff Team 1', tablePlace: 2 },
      { name: 'Playoff Team 2', tablePlace: 3 },
      { name: 'Playoff Team 3', tablePlace: 4 },
      { name: 'Playoff Team 4', tablePlace: 5 },
      { name: 'Playoff Team 5', tablePlace: 6 },
      { name: 'Season Ending Team 1', tablePlace: 7 },
      { name: 'Season Ending Team 2', tablePlace: 8 },
      { name: 'Season Ending Team 3', tablePlace: 9 },
      { name: 'Season Ending Team 4', tablePlace: 10 },
    ],
    winnerColor: '#0f1990',
    positiveColor: '#2667b0',
    negativeColor: '#c55258',
    relegationColor: '#5d191c',
    itemColor: '#000000',
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/hero-bg.jpg)' }}
          aria-label="Hero Section">
          <div className="absolute inset-0 bg-black opacity-60" aria-hidden="true"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              Welcome to <span className="text-accent">Table Top Tippers</span>
            </h1>
            <p className="text-xl text-gray-200 mb-6 mx-auto max-w-2xl">
              Your ultimate destination for competitive tipping and sports excitement! Join our community and showcase
              your sports knowledge.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center">
              {role === null && (
                <button
                  className="bg-accent text-white py-3 px-8 mx-2 mb-4 sm:mb-0 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                  onClick={() => handleNavigate('/register')}>
                  Join Us Now
                </button>
              )}
              <button
                className="bg-accent text-white py-3 px-8 mx-2 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                onClick={() => handleNavigate('/news')}>
                Latest News
              </button>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-gray-100 py-16" aria-labelledby="features-heading">
          <div className="container mx-auto px-4">
            <h2 id="features-heading" className="text-3xl font-semibold text-center mb-12">
              Why Choose Us
            </h2>
            <div className="grid gap-8 md:grid-cols-3">
              {/* Feature 1 */}
              <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                <div className="flex items-center justify-center mb-4">
                  <FaTrophy className="text-accent text-5xl" aria-hidden="true" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">Competitive Tipping</h3>
                <p className="text-center text-gray-700">
                  Challenge yourself and compete with others in predicting game outcomes. Climb the leaderboard and earn
                  bragging rights!
                </p>
              </div>
              {/* Feature 2 */}
              <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                <div className="flex items-center justify-center mb-4">
                  <FaComments className="text-accent text-5xl" aria-hidden="true" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">Interactive Community</h3>
                <p className="text-center text-gray-700">
                  Engage with a passionate community of sports enthusiasts. Share insights, discuss strategies, and
                  build friendships.
                </p>
              </div>
              {/* Feature 3 */}
              <div className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                <div className="flex items-center justify-center mb-4">
                  <FaLightbulb className="text-accent text-5xl" aria-hidden="true" />
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-center">Expert Analysis</h3>
                <p className="text-center text-gray-700">
                  Access expert analyses and insights to help you make informed predictions and stay ahead of the game.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Share Your Table Section */}
        <section className="py-16 bg-white" aria-labelledby="share-table-heading">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                <h2 id="share-table-heading" className="text-3xl font-semibold mb-4 flex items-center">
                  <FaShareAlt className="text-accent text-4xl mr-2" aria-hidden="true" />
                  Share Your Own Table
                </h2>
                <p className="text-lg text-gray-700 mb-4">
                  Now you can easily share your customized tables with friends or on social media!
                </p>
                <p className="text-lg text-gray-700 mb-4">
                  Simply create a table, set it to public, and use the share button to spread the word.
                </p>
                <p className="text-lg text-gray-700 mb-6">
                  Private tables remain accessible only to you, ensuring your predictions stay confidential until you're
                  ready to share.
                </p>
                <div className="flex justify-center md:justify-start">
                  <button
                    className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                    onClick={() => handleNavigate(role === null ? '/register' : '/my-account/tables')}>
                    {role === null ? 'Start Sharing' : 'Go to Your Tables'}
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                {/* Illustration */}
                <FaTable className="text-accent text-9xl" aria-hidden="true" />
              </div>
            </div>
          </div>
        </section>

        {/* Official Tables Section */}
        <section className="py-16 bg-gray-100" aria-labelledby="official-tables-heading">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h2 id="official-tables-heading" className="text-3xl font-semibold mb-4 flex items-center justify-center">
                <FaGlobe className="text-accent text-4xl mr-2" aria-hidden="true" />
                Official Tables from TTT
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                Check out our official tables to see our predictions for the upcoming seasons!
              </p>
              <p className="text-lg text-gray-700 mb-4">
                Use them as inspiration or challenge us with your own predictions.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                Stay ahead of the game by keeping up with the latest standings and stats.
              </p>
              <button
                className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                onClick={() => handleNavigate('/official-tables')}>
                See Official Tables
              </button>
            </div>
          </div>
        </section>

        {/* Simple Tables Section */}
        <section className="bg-gradient-to-r from-gray-100 to-gray-200 py-16" aria-labelledby="simple-tables-heading">
          <div className="container mx-auto px-4">
            <h2 id="simple-tables-heading" className="text-3xl font-semibold text-center mb-8">
              Simple Tables Alpha Release
            </h2>
            <p className="text-lg text-center mb-8 mx-4">
              We're excited to introduce our new Simple Tables feature! Easily track league standings and team
              performances. Check out the sample tables below.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Table 1 */}
              <div
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2"
                aria-label="Sample Table 1">
                <SimpleTable tableData={simpleTableData} />
              </div>
              {/* Table 2 */}
              <div
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2"
                aria-label="Sample Table 2">
                <SimpleTable tableData={simpleTableData2} />
              </div>
              {/* Table 3 */}
              <div
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2"
                aria-label="Sample Table 3">
                <SimpleTable tableData={simpleTableData3} />
              </div>
            </div>
            <p className="text-lg text-center mt-8">
              {role === null
                ? 'Sign up now and create your own customized tables!'
                : 'Create your own customized tables now!'}
            </p>
            <div className="flex justify-center mt-4">
              <button
                className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                onClick={() => handleNavigate(role === null ? '/register' : '/my-account/tables')}>
                {role === null ? 'Get Started' : 'Create Your Table Here'}
              </button>
            </div>
          </div>
        </section>

        {/* Latest News Section */}
        <section className="bg-teal-800 py-16" aria-labelledby="latest-news-heading">
          <div className="container mx-auto px-4">
            <h2 id="latest-news-heading" className="text-3xl font-semibold text-center mb-12 text-white">
              Latest News
            </h2>
            <div className="p-8 bg-gray-100 rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
              <div className="flex items-center mb-4">
                <FaNewspaper className="text-primary text-5xl mr-4" aria-hidden="true" />
                <h3 className="text-2xl font-semibold">We Are Developing Our Service!</h3>
              </div>
              <p className="text-gray-700 mb-4">
                We're hard at work developing our platform to bring you the best tipping experience. Our goal is to
                launch a beta version before the start of the Allsvenskan and Superettan 2025 season.
              </p>
              <p className="text-gray-700 mb-6">
                Join our alpha testing phase to get early access and become a power user. Your feedback will help shape
                the future of Table Top Tippers.
              </p>
              <div className="flex justify-center">
                <button
                  className="bg-primary text-white py-2 px-6 rounded-full font-semibold hover:bg-primary-dark transition duration-300 transform hover:scale-105"
                  onClick={() => handleNavigate('/news')}>
                  Read More
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default HomePage;
