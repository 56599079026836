import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../Contexts/ApiProvider';
import { FaArrowLeft, FaSpinner, FaUserPlus } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../Partials/Footer/MainFooter';
import MainHeader from '../../Partials/Header/MainHeader';
import { Triggerbee } from '../../../types/triggerbee';
import { RegisterUserData } from '../../../types/apimodels';
import { useJwt } from '../../../Contexts/JwtProvider';

declare var triggerbee: Triggerbee;

const RegisterPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registrationAsync } = apiContext;

  useEffect(() => {
    if (role !== null) {
      navigate('/');
    }
  });

  const validationSchema = Yup.object({
    firstName: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    lastName: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    email: Yup.string().email('Invalid email format').min(6, 'Must be at least 6 characters').required('Required'),
    password: Yup.string()
      .min(8, 'Must be at least 8 characters')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        'Password must contain an uppercase letter, a lowercase letter, a number, and a special character'
      )
      .required('Required'),
    phoneNumber: Yup.string().matches(/^\+\d{1,3}\d{9}$/, 'Not a valid phone number'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const registerUserData: RegisterUserData = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email,
        password: values.password,
      };
      const success = await registrationAsync(registerUserData);
      if (success) {
        // Use Triggerbee to identify the user and log the registration event
        try {
          if (triggerbee && typeof triggerbee.identify === 'function' && typeof triggerbee.event === 'function') {
            triggerbee.identify({
              email: values.email,
              firstname: values.firstName,
              lastname: values.lastName,
            });

            triggerbee.event({
              type: 'goal',
              name: 'Created account',
              identity: {
                email: values.email,
              },
            });
          }
        } catch (error) {
          console.error('Triggerbee integration failed', error);
        } finally {
          navigate('/login');
        }
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-6 pt-20 bg-gray-50">
        <div className="self-start mb-6 cursor-pointer text-2xl text-gray-600" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        {/* User Plus Icon */}
        <FaUserPlus className="text-6xl text-primary mb-4" />
        <h1 className="text-4xl font-bold text-primary mb-6 text-center">Register</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          {/* First Name */}
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-lg font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.firstName}</div>
            ) : null}
          </div>
          {/* Last Name */}
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-lg font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.lastName}</div>
            ) : null}
          </div>
          {/* Email */}
          <div className="mb-4">
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="yourmail@here.com"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
            ) : null}
          </div>
          {/* Password */}
          <div className="mb-4">
            <label htmlFor="password" className="block text-lg font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              name="password"
              placeholder="SecurePassword123!"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
            ) : null}
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className={`w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300 ${
              formik.isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}>
            {formik.isSubmitting ? <FaSpinner className="animate-spin mx-auto" /> : 'Register'}
          </button>
          {errorMessage && <div className="mt-3 text-red-500 text-center">{errorMessage}</div>}
        </form>
        {/* Already have an account */}
        <div className="mt-6 flex flex-col items-center space-y-3">
          <p className="text-gray-700">Already have an account?</p>
          <button
            className="w-full max-w-xs bg-secondary text-white py-2 px-4 rounded-md font-semibold hover:bg-secondary-dark transition-colors duration-300"
            onClick={() => navigate('/login')}>
            Login Here
          </button>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default RegisterPage;
