import React from 'react';
import { FaHandshake, FaEnvelope, FaTools, FaDotCircle } from 'react-icons/fa';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const PartnersPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/partners-hero-bg.jpg)' }}
          aria-label="Partners Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              Our <span className="text-accent">Partners</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl">
              Collaborating with innovators to bring you the best experience.
            </p>
          </div>
        </section>

        {/* Partners Content Section */}
        <section className="py-16 bg-white" aria-label="Partners Content">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-semibold text-primary mb-4">
                <FaHandshake className="inline-block text-4xl text-accent mr-2" aria-hidden="true" />
                Partner With Us
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                We are currently working with cutting-edge technologies and looking for partners to join us.
              </p>
              <p className="text-lg text-gray-700 mb-4">
                If you share our vision and passion, we'd love to hear from you!
              </p>
            </div>

            {/* Technology Stack Section */}
            <div className="flex flex-col md:flex-row items-center mb-12">
              <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8 text-center">
                <h3 className="text-2xl font-semibold text-primary mb-4">
                  <FaTools className="inline-block text-3xl text-accent mr-2" aria-hidden="true" />
                  Our Technology Stack
                </h3>
                <ul className="list-none text-gray-700">
                  <li className="text-lg mb-2">
                    <FaDotCircle className="inline-block text-accent mr-2" aria-hidden="true" />
                    <strong>.NET</strong>
                  </li>
                  <li className="text-lg mb-2">
                    <FaDotCircle className="inline-block text-accent mr-2" aria-hidden="true" />
                    <strong>React</strong>
                  </li>
                  <li className="text-lg mb-2">
                    <FaDotCircle className="inline-block text-accent mr-2" aria-hidden="true" />
                    <strong>Azure</strong>
                  </li>
                </ul>
              </div>
              <div className="md:w-1/2 text-center">
                <h3 className="text-2xl font-semibold text-primary mb-4">
                  <FaTools className="inline-block text-3xl text-accent mr-2" aria-hidden="true" />
                  Enhancing User Experience
                </h3>
                <p className="text-lg text-gray-700 mb-4">
                  We utilize tools like{' '}
                  <a
                    href="https://www.triggerbee.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-accent font-semibold hover:underline">
                    Triggerbee
                  </a>{' '}
                  to improve our customer experience.
                </p>
              </div>
            </div>

            {/* Call to Action Section */}
            <div className="text-center">
              <p className="text-lg text-gray-700 mb-6">
                If you are interested in becoming a partner, please contact us:
              </p>
              <a
                href="mailto:info@tabletoptippers.com"
                className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105 inline-block">
                <FaEnvelope className="inline-block text-xl mr-2" aria-hidden="true" />
                Contact Us
              </a>
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default PartnersPage;
