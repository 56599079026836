import React, { useContext, useEffect, useState, useRef } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import defaultProfilePicture from '../../../Assets/Images/DefaultPP.webp';
import { FaUser, FaTable, FaSignOutAlt } from 'react-icons/fa';
import { MyAccountUserResponse } from '../../../../types/apimodels';

const MyAccountPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [user, setUser] = useState<MyAccountUserResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const hasFetchedUser = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { GetMyAccountUserAsync, logoutAsync } = apiContext;

  useEffect(() => {
    if (role === null) {
      navigate('/notauthorized');
    } else if (!hasFetchedUser.current) {
      fetchUser();
      hasFetchedUser.current = true;
    }
  }, [role, navigate]);

  const fetchUser = async () => {
    try {
      const response = await GetMyAccountUserAsync();
      setUser(response);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    const response = await logoutAsync();
    if (response) {
      navigate('/');
    }
  };

  const handleNavigateToProfile = () => {
    navigate('/my-account/profile', { state: { user } });
  };

  const handleNavigateToTables = () => {
    navigate('/my-account/tables');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-6 py-8 pt-20">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
            </div>
          ) : (
            user && (
              <div className="mb-8">
                <img
                  src={user.imageUrl || defaultProfilePicture}
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover mx-auto mb-4"
                />
                <h1 className="text-3xl font-bold text-primary mb-2">Welcome, {user.name}!</h1>
              </div>
            )
          )}

          {/* Action Cards */}
          {!loading && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
              {/* Profile Card */}
              <div
                className="flex flex-col items-center bg-gray-100 text-black py-8 px-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
                onClick={handleNavigateToProfile}>
                <FaUser className="text-4xl mb-4" />
                <span>My Profile</span>
              </div>

              {/* Tables Card */}
              <div
                className="flex flex-col items-center bg-gray-100 text-black py-8 px-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
                onClick={handleNavigateToTables}>
                <FaTable className="text-4xl mb-4" />
                <span>My Tables</span>
              </div>

              {/* Logout Card */}
              <div
                className="flex flex-col items-center bg-gray-100 text-black py-8 px-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transform hover:scale-105 transition-transform duration-300"
                onClick={handleLogout}>
                <FaSignOutAlt className="text-4xl mb-4" />
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default MyAccountPage;
