import React, { useEffect } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { FaTrophy, FaChartLine, FaCalendarAlt, FaUsers, FaFlag, FaTable, FaUser } from 'react-icons/fa';

const AdminPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const adminOptions = [
    { name: 'Leagues', path: '/admin/leagues', icon: <FaTrophy /> },
    { name: 'League Season Stats', path: '/admin/lss', icon: <FaChartLine /> },
    { name: 'Rounds', path: '/admin/rounds', icon: <FaCalendarAlt /> },
    { name: 'Team Season Stats', path: '/admin/tss', icon: <FaUsers /> },
    { name: 'Matches', path: '/admin/matches', icon: <FaFlag /> },
    { name: 'Teams', path: '/admin/teams', icon: <FaTable /> },
    { name: 'Users', path: '/admin/users', icon: <FaUser /> },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-6 pt-20">
        <h1 className="text-4xl font-bold text-primary text-center mb-8">Admin Dashboard</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {adminOptions.map(option => (
            <div
              key={option.name}
              className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center justify-center text-center hover:bg-gray-50 transition duration-300 cursor-pointer"
              onClick={() => handleNavigate(option.path)}>
              <div className="text-4xl text-primary mb-4">{option.icon}</div>
              <h2 className="text-xl font-semibold text-gray-800">{option.name}</h2>
              <p className="text-gray-600 mt-2">Manage {option.name.toLowerCase()}</p>
            </div>
          ))}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminPage;
