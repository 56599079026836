import React, { useContext } from 'react';
import { useJwt } from '../../../Contexts/JwtProvider';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../Contexts/ApiProvider';
import { FaFacebook, FaLinkedin, FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';

const MainFooter: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (apiContext) {
      const result = await apiContext.logoutAsync();
      if (result) {
        navigate('/');
      } else {
        console.log('Logout failed');
      }
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-primary text-white py-8 px-4">
      <div className="max-w-screen-xl mx-auto">
        {/* Footer Top */}
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8 border-b border-gray-700 pb-8">
          {/* Company */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Company</h5>
            <ul className="space-y-2">
              {['About', 'Our team', 'Career', 'Partners', 'News', 'Contact'].map((item, index) => (
                <li key={index}>
                  <button
                    className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                    onClick={() => handleNavigate(`/${item.toLowerCase().replace(' ', '-')}`)}>
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* Our Product */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Our Product</h5>
            <ul className="space-y-2">
              {['Leagues', 'Leaderboards', 'Roadmap'].map((item, index) => (
                <li key={index}>
                  <button
                    className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                    onClick={() => handleNavigate(`/${item.toLowerCase().replace(' ', '-')}`)}>
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* Tables */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Tables</h5>
            <ul className="space-y-2">
              {['Official tables', 'Create table'].map((item, index) => (
                <li key={index}>
                  <button
                    className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                    onClick={() => handleNavigate(`/${item.toLowerCase().replace(' ', '-')}`)}>
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {/* Account */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Account</h5>
            <ul className="space-y-2">
              {role === 'admin' && (
                <>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={() => handleNavigate('/my-account')}>
                      My Account
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={() => handleNavigate('/admin')}>
                      Admin
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={handleLogout}>
                      Log Out
                    </button>
                  </li>
                </>
              )}
              {role === 'user' && (
                <>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={() => handleNavigate('/my-account')}>
                      My Account
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={handleLogout}>
                      Log Out
                    </button>
                  </li>
                </>
              )}
              {!role && (
                <>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={() => handleNavigate('/login')}>
                      Login
                    </button>
                  </li>
                  <li>
                    <button
                      className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                      onClick={() => handleNavigate('/register')}>
                      Register
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
          {/* Contact Info */}
          <div>
            <h5 className="text-xl font-semibold mb-4">Contact Info</h5>
            <p className="mb-2">
              Email:{' '}
              <a
                href="mailto:info@tabletoptippers.com"
                className="text-white hover:text-accent transition-colors duration-300">
                info@tabletoptippers.com
              </a>
            </p>
            <p className="mb-2">Office: Myggdalsvägen 16, 13543 Tyresö</p>
            <p>Phone: +46 76 555 6937</p>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-8">
          <p className="text-center md:text-left mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} Table Top Tippers. All rights reserved.
          </p>
          {/* Social Media Icons */}
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61564188440214"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-accent transition-colors duration-300">
              <FaFacebook size={24} />
            </a>
            <a
              href="https://www.twitter.com/TableTopTippers"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-accent transition-colors duration-300">
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.instagram.com/tabletoptipper"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-accent transition-colors duration-300">
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.linkedin.com/company/tabletoptippers"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-accent transition-colors duration-300">
              <FaLinkedin size={24} />
            </a>
            <a
              href="https://www.discord.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-accent transition-colors duration-300">
              <FaDiscord size={24} />
            </a>
          </div>
        </div>
        {/* Legal Links */}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
            onClick={() => handleNavigate('/terms')}>
            Terms of Service
          </button>
          <button
            className="text-white hover:text-accent transition-colors duration-300 focus:outline-none"
            onClick={() => handleNavigate('/privacy')}>
            Privacy Policy
          </button>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
