import { UUID } from 'crypto';
import { jwtDecode } from 'jwt-decode';
import React, { createContext, useState, useEffect, useContext } from 'react';
interface JwtContextType {
  jwt: string | null;
  role: string | null;
  handlerId: UUID | null;
  setJwt: (jwt: string | null) => void;
  handleLogout: () => void;
  handleLogin: () => void;
}
interface JwtPayload {
  role: string;
  handlerId: UUID;
  exp: number;
}
const JwtContext = createContext<JwtContextType | undefined>(undefined);

export const JwtProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [jwt, setJwt] = useState<string | null>(getJwtFromLocalStorage());
  const [role, setRole] = useState<string | null>(getRoleFromJwt(jwt));
  const [handlerId, setHandlerId] = useState<UUID | null>(getHandlerIdFromJwt(jwt));
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'jwt') {
        const jwt = getJwtFromLocalStorage();
        setJwt(jwt);
        setRole(getRoleFromJwt(jwt));
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('jwt');
    setJwt(null);
    setRole(null);
    setHandlerId(null);
  };

  const handleLogin = () => {
    const jwt = getJwtFromLocalStorage();
    setJwt(jwt);
    setRole(getRoleFromJwt(jwt));
    setHandlerId(getHandlerIdFromJwt(jwt));
  };
  return (
    <JwtContext.Provider value={{ jwt, role, handlerId, setJwt, handleLogout, handleLogin }}>
      {children}
    </JwtContext.Provider>
  );
};

export const useJwt = (): JwtContextType => {
  const context = useContext(JwtContext);
  if (!context) {
    throw new Error('useJwt must be used within a JwtProvider');
  }
  return context;
};

const getJwtFromLocalStorage = (): string | null => {
  const jwt = localStorage.getItem('jwt');
  return jwt && !isJwtExpired(jwt) ? jwt : null;
};

const getRoleFromJwt = (jwt: string | null): string | null => {
  if (!jwt) return null;
  try {
    const decodedToken = jwtDecode<JwtPayload>(jwt);
    return decodedToken.role;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
};

const getHandlerIdFromJwt = (jwt: string | null): UUID | null => {
  if (!jwt) return null;
  try {
    const decodedToken = jwtDecode<JwtPayload>(jwt);
    return decodedToken.handlerId;
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return null;
  }
};

const isJwtExpired = (jwt: string): boolean => {
  try {
    const decodedToken = jwtDecode<JwtPayload>(jwt);
    return decodedToken.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Failed to decode JWT', error);
    return true;
  }
};
