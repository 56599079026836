import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { Country } from '../../../../../types/enums';
import { LeagueResponse } from '../../../../../types/apimodels';
import { UUID } from 'crypto';

interface FormValues {
  name: string;
  numberOfTeams: number;
  country: string;
  numberOfRounds: number;
}

const AdminEditLeaguePage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const { leagueId } = useParams<{ leagueId: UUID }>();
  const [league, setLeague] = useState<LeagueResponse | null>(null);
  const [resultMessage, setResultMessage] = useState<string | null>(null);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchLeague();
    }
  }, [role, navigate, leagueId]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getLeagueIdAsync, updateLeagueAsync } = apiContext;

  const fetchLeague = async () => {
    if (leagueId) {
      const response = await getLeagueIdAsync(leagueId);
      setLeague(response);
    }
  };

  // Get numeric values of Country enum and convert to strings
  const countryValues = Object.values(Country)
    .filter(value => typeof value === 'number')
    .map(value => value.toString());

  const validationSchema = Yup.object({
    name: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    numberOfTeams: Yup.number().min(1, 'Must be at least 1').required('Required'),
    country: Yup.string().oneOf(countryValues, 'Invalid country').required('Required'),
    numberOfRounds: Yup.number().min(1, 'Must be at least 1').required('Required'),
  });

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: league?.name || '',
      numberOfTeams: league?.numberOfTeams || 0,
      country: league ? league.country.toString() : '',
      numberOfRounds: league?.numberOfRounds || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const request = {
        id: leagueId!,
        ...values,
        country: parseInt(values.country, 10) as Country,
      };
      const response = await updateLeagueAsync(request);
      if (response) {
        setResultMessage('League updated successfully');
        navigate(`/admin/leagues/detail/${leagueId}`);
      } else {
        setResultMessage('Failed to update league');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Update League</h1>
        </div>
        {league ? (
          <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
            {/* League Name */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                League Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Enter league name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>
              )}
            </div>
            {/* Number of Teams */}
            <div className="mb-4">
              <label htmlFor="numberOfTeams" className="block text-sm font-medium text-gray-700">
                Number of Teams <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="numberOfTeams"
                placeholder="Enter number of teams"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.numberOfTeams}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.numberOfTeams && formik.errors.numberOfTeams && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.numberOfTeams}</div>
              )}
            </div>
            {/* Country */}
            <div className="mb-4">
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country <span className="text-red-500">*</span>
              </label>
              <select
                name="country"
                className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value="" label="Select country" />
                {Object.keys(Country)
                  .filter(key => isNaN(Number(key)))
                  .map(key => {
                    const value = Country[key as keyof typeof Country] as number;
                    return (
                      <option key={key} value={value.toString()}>
                        {key.replace(/([A-Z])/g, ' $1').trim()}
                      </option>
                    );
                  })}
              </select>
              {formik.touched.country && formik.errors.country && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.country}</div>
              )}
            </div>
            {/* Number of Rounds */}
            <div className="mb-6">
              <label htmlFor="numberOfRounds" className="block text-sm font-medium text-gray-700">
                Number of Rounds <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="numberOfRounds"
                placeholder="Enter number of rounds"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
                value={formik.values.numberOfRounds}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.numberOfRounds && formik.errors.numberOfRounds && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.numberOfRounds}</div>
              )}
            </div>
            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              Update League
            </button>
          </form>
        ) : (
          <div className="text-center text-gray-500">Loading league details...</div>
        )}
        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminEditLeaguePage;
