import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';

const AdminRegisterUserPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { adminRegistrationAsync } = apiContext;

  const validationSchema = Yup.object({
    firstName: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    lastName: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    email: Yup.string().email('Invalid email format').min(6, 'Must be at least 6 characters').required('Required'),
    roleName: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roleName: 'user',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const success = await adminRegistrationAsync(values);
      if (success) {
        setResultMessage('Registration was successful');
      } else {
        setResultMessage('Registration failed');
      }
    },
  });

  const [resultMessage, setResultMessage] = useState<string | null>(null);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <div className="flex items-center mb-4">
          <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-semibold text-center flex-grow">Register</h1>
        </div>
        <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
          <div className="mb-4">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label htmlFor="roleName" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <select
              name="roleName"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={formik.values.roleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
            {formik.touched.roleName && formik.errors.roleName ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.roleName}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Register
          </button>
        </form>
        {resultMessage && <div className="mt-4 text-center text-lg font-semibold text-indigo-600">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterUserPage;
