import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../Partials/Footer/MainFooter';
import MainHeader from '../../../Partials/Header/MainHeader';
import { MyAccountUserResponse, UpdateUserRequest } from '../../../../types/apimodels';

const UpdateMyUserPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const location = useLocation();
  const user: MyAccountUserResponse = location.state?.user; // Access user object passed from MyAccountProfilePage

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { updateUserAsync } = apiContext;

  const validationSchema = Yup.object({
    newFirstName: Yup.string().nullable(),
    newLastName: Yup.string().nullable(),
    newEmail: Yup.string().email('Invalid email format').nullable(),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        'Password must contain an uppercase letter, a lowercase letter, a number, and a special character'
      )
      .nullable(),
    newPhoneNumber: Yup.string()
      .matches(/^\+\d{1,3}\d{9}$/, 'Not a valid phone number')
      .nullable(),
    newImageUrl: Yup.string().url('Invalid URL format').nullable(),
    currentPassword: Yup.string().required('Current password is required'),
  });

  const formik = useFormik({
    initialValues: {
      newFirstName: user?.firstName || '',
      newLastName: user?.lastName || '',
      newEmail: user?.userName || '',
      newPassword: '',
      newPhoneNumber: user?.phoneNumber || '',
      newImageUrl: user?.imageUrl || '',
      newIsPublicProfile: user?.isPublicProfile || false,
      currentPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const updateUserRequest: UpdateUserRequest = {
        newFirstName: values.newFirstName.trim() || null,
        newLastName: values.newLastName.trim() || null,
        newEmail: values.newEmail || null,
        newPassword: values.newPassword || null,
        newPhoneNumber: values.newPhoneNumber || null,
        newRole: null,
        currentPassword: values.currentPassword,
        newImageUrl: values.newImageUrl || null,
        isPublicProfile: values.newIsPublicProfile || false,
      };
      const success = await updateUserAsync(updateUserRequest);
      if (success) {
        alert('Update successful!');
        navigate('/myaccount');
      } else {
        alert('Update failed.');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-6 py-8 pt-20">
        <div className="flex items-center mb-6 cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg mx-auto">
          <h1 className="text-3xl font-bold text-primary text-center mb-4">Update Profile</h1>
          <form onSubmit={formik.handleSubmit}>
            {/* First Name Field */}
            <div className="mb-4">
              <label htmlFor="newFirstName" className="block text-lg font-medium text-neutral mb-2">
                First Name
              </label>
              <input
                type="text"
                name="newFirstName"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newFirstName && typeof formik.errors.newFirstName === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newFirstName}</div>
              )}
            </div>

            {/* Last Name Field */}
            <div className="mb-4">
              <label htmlFor="newLastName" className="block text-lg font-medium text-neutral mb-2">
                Last Name
              </label>
              <input
                type="text"
                name="newLastName"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newLastName && typeof formik.errors.newLastName === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newLastName}</div>
              )}
            </div>

            {/* Email Field */}
            <div className="mb-4">
              <label htmlFor="newEmail" className="block text-lg font-medium text-neutral mb-2">
                Email
              </label>
              <input
                type="email"
                name="newEmail"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newEmail && typeof formik.errors.newEmail === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newEmail}</div>
              )}
            </div>

            {/* Phone Number Field */}
            <div className="mb-4">
              <label htmlFor="newPhoneNumber" className="block text-lg font-medium text-neutral mb-2">
                Phone Number
              </label>
              <input
                type="text"
                name="newPhoneNumber"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newPhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newPhoneNumber && typeof formik.errors.newPhoneNumber === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newPhoneNumber}</div>
              )}
            </div>

            {/* Profile Image URL Field */}
            <div className="mb-4">
              <label htmlFor="newImageUrl" className="block text-lg font-medium text-neutral mb-2">
                Profile Image URL
              </label>
              <input
                type="text"
                name="newImageUrl"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newImageUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newImageUrl && typeof formik.errors.newImageUrl === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newImageUrl}</div>
              )}
            </div>

            {/* New Password Field */}
            <div className="mb-4">
              <label htmlFor="newPassword" className="block text-lg font-medium text-neutral mb-2">
                New Password
              </label>
              <input
                type="password"
                name="newPassword"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newPassword && typeof formik.errors.newPassword === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newPassword}</div>
              )}
            </div>

            {/* Current Password Field */}
            <div className="mb-4">
              <label htmlFor="currentPassword" className="block text-lg font-medium text-neutral mb-2">
                Current Password
              </label>
              <input
                type="password"
                name="currentPassword"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.currentPassword && typeof formik.errors.currentPassword === 'string' && (
                <div className="text-red-500 text-sm mt-1">{formik.errors.currentPassword}</div>
              )}
            </div>

            {/* Public Profile Toggle */}
            <div className="mb-4 flex items-center">
              <label htmlFor="newIsPublicProfile" className="block text-lg font-medium text-neutral mb-2 mr-4">
                Public Profile
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="newIsPublicProfile"
                  id="newIsPublicProfile"
                  checked={formik.values.newIsPublicProfile}
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300">
              Update
            </button>
          </form>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default UpdateMyUserPage;
