import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useNavigate, useLocation } from 'react-router-dom';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import defaultProfilePicture from '../../../Assets/Images/DefaultPP.webp';
import {
  FaSpinner,
  FaUserCircle,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaUserFriends,
  FaSignOutAlt,
  FaEdit,
  FaUsers,
  FaEye,
  FaEyeSlash,
} from 'react-icons/fa';
import { MyAccountUserResponse } from '../../../../types/apimodels';
import { FaArrowLeft } from 'react-icons/fa6';

const MyAccountProfilePage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<MyAccountUserResponse | null>(location.state?.user || null);
  const [loading, setLoading] = useState<boolean>(!user);
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { GetMyAccountUserAsync, logoutAsync, resendEmailConfirmAsync } = apiContext;

  useEffect(() => {
    if (role === null) {
      navigate('/notauthorized');
    } else if (!user) {
      fetchUser();
    }
  }, [role, navigate, user]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await GetMyAccountUserAsync();
      setUser(response);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
  };

  const handleLogout = async () => {
    const response = await logoutAsync();
    if (response) {
      handleNavigate('/');
    }
  };

  const formatDateTime = (date: Date): string => {
    return new Date(date).toLocaleString();
  };

  const handleSendConfirmMail = async () => {
    const response = await resendEmailConfirmAsync();
    if (response) {
      setMessage({ text: 'Email sent successfully, check your inbox.', type: 'success' });
    } else {
      setMessage({ text: 'Something went wrong, please try again.', type: 'error' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-6 py-8 pt-20">
        <div className="flex items-center mb-6 cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-lg">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <FaSpinner className="animate-spin text-4xl text-gray-500" />
            </div>
          ) : (
            user && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Profile Information */}
                <div className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-md mb-6 md:mb-0">
                  <div className="mb-6">
                    <img
                      src={user.imageUrl || defaultProfilePicture}
                      alt="Profile"
                      className="w-36 h-36 rounded-full object-cover"
                    />
                  </div>
                  <div className="text-center w-full">
                    <div className="flex items-center justify-center mb-4">
                      <FaUserCircle className="text-primary text-3xl mr-3" />
                      <h1 className="text-2xl font-bold text-primary">{user.name}</h1>
                    </div>
                    <div className="flex items-center justify-center mb-2">
                      <FaEnvelope className="text-gray-600 text-xl mr-3" />
                      <p className="text-lg text-neutral">{user.userName}</p>
                    </div>
                    {user.phoneNumber && (
                      <div className="flex items-center justify-center mb-2">
                        <FaPhone className="text-gray-600 text-xl mr-3" />
                        <p className="text-lg text-neutral">{user.phoneNumber}</p>
                      </div>
                    )}
                    <div className="flex items-center justify-center mb-2">
                      <FaCalendarAlt className="text-gray-600 text-xl mr-3" />
                      <p className="text-lg text-neutral">Member since: {formatDateTime(user.created)}</p>
                    </div>
                    <div className="flex items-center justify-center mb-2">
                      <FaUserFriends className="text-gray-600 text-xl mr-3" />
                      <p className="text-lg text-neutral">Friends: {user.friends}</p>
                    </div>
                    <div className="flex items-center justify-center mb-2">
                      <FaUserFriends className="text-gray-600 text-xl mr-3" />
                      <p className="text-lg text-neutral">Pending friend requests: {user.friendRequests}</p>
                    </div>
                    {/* Public Profile Information */}
                    <div className="flex items-center justify-center mb-2">
                      {user.isPublicProfile ? (
                        <>
                          <FaEye className="text-green-600 text-xl mr-3" />
                          <p className="text-lg text-neutral">Public Profile: Yes</p>
                        </>
                      ) : (
                        <>
                          <FaEyeSlash className="text-red-600 text-xl mr-3" />
                          <p className="text-lg text-neutral">Public Profile: No</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex flex-col items-center md:items-start">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full">
                    <button
                      className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
                      onClick={handleLogout}>
                      <FaSignOutAlt className="mr-2 text-xl" />
                      <span className="text-lg font-semibold">Logout</span>
                    </button>
                    <button
                      className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
                      onClick={() => handleNavigate('/my-account/profile/update-profile', { user })}>
                      <FaEdit className="mr-2 text-xl" />
                      <span className="text-lg font-semibold">Update Profile</span>
                    </button>
                    <button
                      className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
                      onClick={() => handleNavigate('/my-account/profile/friends')}>
                      <FaUsers className="mr-2 text-xl" />
                      <span className="text-lg font-semibold">Friends</span>
                    </button>
                    {!user.emailConfirmed && (
                      <button
                        className="flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-4 px-6 rounded-lg shadow-md hover:bg-gray-100 transition duration-300"
                        onClick={handleSendConfirmMail}>
                        <FaEnvelope className="mr-2 text-xl" />
                        <span className="text-lg font-semibold">Confirm Email</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        {/* Message Display */}
        {message && (
          <div
            className={`mt-4 text-center p-4 rounded-md ${
              message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
            }`}
            role="alert">
            {message.text}
          </div>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default MyAccountProfilePage;
