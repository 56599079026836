import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const TermsPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 p-6 pt-10 container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold text-primary mb-6">Terms of Agreement</h1>
          <p className="text-lg text-neutral mb-6">
            Please read these terms and conditions carefully before using our service.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">1. Introduction</h2>
          <p className="text-neutral mb-4">
            Welcome to Table Top Tippers. By accessing or using our platform, you agree to be bound by these terms and
            conditions. If you do not agree with any part of the terms, you may not access the service.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">2. Use of the Service</h2>
          <p className="text-neutral mb-4">
            You agree to use the service only for lawful purposes and in a way that does not infringe the rights of,
            restrict or inhibit anyone else's use and enjoyment of the service.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">3. Intellectual Property</h2>
          <p className="text-neutral mb-4">
            All content included on the platform, such as text, graphics, logos, images, and software, is the property
            of Table Top Tippers or its content suppliers and protected by intellectual property laws.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">4. User Accounts</h2>
          <p className="text-neutral mb-4">
            When you create an account with us, you must provide us with information that is accurate, complete, and
            current at all times. Failure to do so constitutes a breach of the terms, which may result in immediate
            termination of your account.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">5. Termination</h2>
          <p className="text-neutral mb-4">
            We may terminate or suspend access to our service immediately, without prior notice or liability, for any
            reason whatsoever, including without limitation if you breach the terms.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">6. Changes to Terms</h2>
          <p className="text-neutral mb-4">
            We reserve the right to modify or replace these terms at any time. If a revision is material, we will
            provide at least 30 days' notice prior to any new terms taking effect.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">7. Contact Us</h2>
          <p className="text-neutral mb-4">
            If you have any questions about these terms, please contact us at{' '}
            <a href="mailto:info@tabletoptippers.com" className="text-accent hover:underline">
              info@tabletoptippers.com
            </a>
            .
          </p>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default TermsPage;
