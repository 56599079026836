import React, { useContext, useState } from 'react';
import MainHeader from '../../Partials/Header/MainHeader';
import MainFooter from '../../Partials/Footer/MainFooter';
import { ApiContext } from '../../../Contexts/ApiProvider';
import { SendFeedbackRequest } from '../../../types/apimodels';

const ContactPage: React.FC = () => {
  const [from, setFrom] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('General Inquiry');
  const [responseMessage, setResponseMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiContext = useContext(ApiContext);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { sendFeedbackAsync } = apiContext;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage(null);

    const request: SendFeedbackRequest = {
      from: from || undefined,
      message,
      category: subject,
    };

    try {
      const result = await sendFeedbackAsync(request);

      if (result === 200) {
        setResponseMessage({ type: 'success', text: 'Your message has been sent successfully!' });
        setFrom('');
        setMessage('');
        setSubject('General Inquiry');
      } else if (result === 429) {
        setResponseMessage({
          type: 'error',
          text: "You've reached the message limit for today. Please try again tomorrow or contact us directly via email.",
        });
      } else {
        setResponseMessage({
          type: 'error',
          text: 'An error occurred while sending your message. Please try again later.',
        });
      }
    } catch (error) {
      setResponseMessage({
        type: 'error',
        text: 'An unexpected error occurred. Please try again later.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/contact-hero-bg.jpg)' }}
          aria-label="Contact Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              Get in Touch with <span className="text-accent">Table Top Tippers</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl">
              We'd love to hear from you! Whether you have questions, feedback, or just want to say hello.
            </p>
          </div>
        </section>

        {/* Contact Form Section */}
        <section className="py-16 bg-white" aria-label="Contact Form">
          <div className="container mx-auto px-4">
            <div className="max-w-2xl mx-auto bg-gray-50 p-8 rounded-lg shadow-md">
              <h2 className="text-3xl font-semibold mb-6 text-center">Send Us a Message</h2>
              <form onSubmit={handleSubmit} noValidate>
                <div className="mb-4">
                  <label htmlFor="from" className="block text-gray-700 font-medium mb-2">
                    Your Name or Email
                  </label>
                  <input
                    type="text"
                    id="from"
                    name="from"
                    value={from}
                    onChange={e => setFrom(e.target.value)}
                    placeholder="Enter your name or email"
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="subject" className="block text-gray-700 font-medium mb-2">
                    Subject
                  </label>
                  <select
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent">
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Support">Support</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-gray-700 font-medium mb-2">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    rows={6}
                    required
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-accent"
                    placeholder="Write your message here..."></textarea>
                </div>
                {responseMessage && (
                  <div
                    className={`mb-4 text-center font-medium ${
                      responseMessage.type === 'success' ? 'text-green-600' : 'text-red-600'
                    }`}>
                    {responseMessage.text}
                  </div>
                )}
                <div className="flex justify-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105 ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}>
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default ContactPage;
