import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../Contexts/JwtProvider';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { ConfirmEmailRequest } from '../../../../types/apimodels';

const ConfirmEmail: React.FC = () => {
  const location = useLocation();
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false);
  const navigate = useNavigate();

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { confirmEmailAsync } = apiContext;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryEmail = queryParams.get('email');
    const queryToken = queryParams.get('token');

    setEmail(queryEmail);
    setToken(queryToken);

    if (queryEmail && queryToken && !hasConfirmed) {
      (async () => {
        setHasConfirmed(true);
        const request: ConfirmEmailRequest = { email: queryEmail, token: queryToken };
        const success = await confirmEmailAsync(request);

        setIsLoading(false);
        if (success) {
          if (role === null) {
            setMessage('Successfully confirmed your email. Redirecting you to the login page in x seconds.');
          } else {
            setMessage('Successfully confirmed your email. Redirecting you to the home page in x seconds.');
          }
        } else {
          if (role === null) {
            setMessage('Could not confirm your email. Redirecting you to the login page in x seconds.');
          } else {
            setMessage('Could not confirm your email. Redirecting you to the home page in x seconds.');
          }
        }

        const interval = setInterval(() => {
          setCountdown(prevCountdown => {
            if (prevCountdown === 1) {
              clearInterval(interval);
              if (role === null) {
                navigate('/login');
              } else {
                navigate('/');
              }
            }
            return prevCountdown - 1;
          });
        }, 1000);
      })();
    } else {
      setIsLoading(false);
    }
  }, [location.search, confirmEmailAsync, role, navigate, hasConfirmed]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-6 text-center">
        <h1 className="text-4xl font-bold text-primary mb-6">Confirming your email...</h1>
        {isLoading ? (
          <p className="text-lg text-neutral">Please wait while we confirm your email...</p>
        ) : message ? (
          <p className="text-lg text-neutral">{message.replace('x', countdown.toString())}</p>
        ) : (
          <p className="text-lg text-neutral">Checking with the API...</p>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default ConfirmEmail;
