import React, { useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../../Partials/Header/MainHeader';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { LeagueResponse } from '../../../../../types/apimodels';
import { Country } from '../../../../../types/enums';
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import { UUID } from 'crypto';

type SortField = 'id' | 'name' | 'country' | 'numberOfTeams';

const AdminHandleLeaguesPage: React.FC = () => {
  const { role } = useJwt();
  const navigate = useNavigate();
  const [leagues, setLeagues] = useState<LeagueResponse[]>([]);
  const [filteredLeagues, setFilteredLeagues] = useState<LeagueResponse[]>([]);
  const [filterCountry, setFilterCountry] = useState<string>('');
  const [sortField, setSortField] = useState<SortField>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const apiContext = useContext(ApiContext);
  const hasFetchedLeagues = useRef(false);

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    } else {
      fetchLeagues();
    }
  }, [role, navigate]);

  const fetchLeagues = async () => {
    if (apiContext?.getAllLeaguesAsync && !hasFetchedLeagues.current) {
      hasFetchedLeagues.current = true;
      const response = await apiContext.getAllLeaguesAsync();
      if (response) {
        setLeagues(response);
        setFilteredLeagues(response);
      }
    }
  };

  const handleDeleteLeague = async (leagueId: UUID) => {
    const confirmDelete = window.confirm('Do you really want to delete this league?');
    if (confirmDelete && apiContext?.deleteLeagueAsync) {
      const success = await apiContext.deleteLeagueAsync(leagueId);
      if (success) {
        setLeagues(leagues.filter(league => league.id !== leagueId));
        setFilteredLeagues(filteredLeagues.filter(league => league.id !== leagueId));
      } else {
        alert('Failed to delete league');
      }
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleFilterChange = () => {
    let filtered = leagues;

    if (filterCountry !== '') {
      filtered = filtered.filter(league => league.country.toString() === filterCountry);
    }

    setFilteredLeagues(filtered);
  };

  const handleSortChange = (sortValue: string) => {
    if (!sortValue) return;

    const [field, direction] = sortValue.split(':') as [SortField, 'asc' | 'desc'];
    setSortField(field);
    setSortDirection(direction);

    const sorted = [...filteredLeagues].sort((a, b) => {
      const aField = a[field];
      const bField = b[field];

      if (aField == null || bField == null) {
        return 0; // Handle null values
      }

      if (aField < bField) return direction === 'asc' ? -1 : 1;
      if (aField > bField) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredLeagues(sorted);
  };

  useEffect(() => {
    handleFilterChange();
  }, [filterCountry, leagues]);

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
          <FaArrowLeft size={24} />
        </button>
        <h1 className="text-3xl font-bold text-primary text-center mb-6">Manage Leagues</h1>
        <div className="flex justify-end mb-6">
          {/* Filter and Sort Controls */}
          <div className="flex space-x-4">
            {/* Filter by Country */}
            <select
              className="border border-gray-300 rounded-md py-2 px-3"
              value={filterCountry}
              onChange={e => setFilterCountry(e.target.value)}>
              <option value="">All Countries</option>
              {Object.keys(Country)
                .filter(key => isNaN(Number(key)))
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={value} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {/* Sort Options */}
            <select
              className="border border-gray-300 rounded-md py-2 px-3"
              value={`${sortField}:${sortDirection}`}
              onChange={e => handleSortChange(e.target.value)}>
              <option value="">Sort By</option>
              <option value="name:asc">Name (A-Z)</option>
              <option value="name:desc">Name (Z-A)</option>
              <option value="country:asc">Country (A-Z)</option>
              <option value="country:desc">Country (Z-A)</option>
              <option value="numberOfTeams:asc">Teams (Ascending)</option>
              <option value="numberOfTeams:desc">Teams (Descending)</option>
            </select>
          </div>
        </div>
        {/* Leagues Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Name</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Country</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Teams</th>
                <th className="py-3 px-6 bg-gray-100 text-left text-sm font-medium text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLeagues.map(league => (
                <tr
                  key={league.id}
                  className="border-b cursor-pointer hover:bg-gray-100"
                  onClick={() => handleNavigate(`/admin/leagues/detail/${league.id}`)}>
                  <td className="py-4 px-6 text-sm text-gray-700">{league.name}</td>
                  <td className="py-4 px-6 text-sm text-gray-700">
                    {Country[league.country].replace(/([A-Z])/g, ' $1').trim()}
                  </td>
                  <td className="py-4 px-6 text-sm text-gray-700">{league.numberOfTeams}</td>
                  <td className="py-4 px-6 text-sm text-gray-700">
                    <div
                      className="flex space-x-2"
                      onClick={e => e.stopPropagation()} // Prevent row click when clicking on buttons
                    >
                      <button
                        className="flex items-center text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded-md"
                        onClick={() => handleNavigate(`/admin/leagues/edit/${league.id}`)}>
                        <FaEdit className="mr-1" />
                        Edit
                      </button>
                      <button
                        className="flex items-center text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded-md"
                        onClick={() => handleDeleteLeague(league.id)}>
                        <FaTrash className="mr-1" />
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {filteredLeagues.length === 0 && (
                <tr>
                  <td colSpan={4} className="py-4 px-6 text-center text-sm text-gray-500">
                    No leagues found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminHandleLeaguesPage;
