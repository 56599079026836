import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const ForgottenPassword: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { resetpasswordTokenAsync } = apiContext;

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const success = await resetpasswordTokenAsync(values.email);
      if (success) {
        navigate('/');
      } else {
        setErrorMessage('Something went wrong, try again');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-6">
        <div className="self-start mb-6 cursor-pointer text-2xl" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        <h1 className="text-4xl font-bold text-primary mb-6 text-center">Reset Password</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full max-w-md bg-gray-100 p-6 border border-gray-300 rounded-lg shadow-lg">
          <div className="mb-4">
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
            ) : null}
          </div>
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300">
            Reset Password
          </button>
        </form>
        {errorMessage && <div className="mt-3 text-red-500 text-center">{errorMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default ForgottenPassword;
