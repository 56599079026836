import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { FaEnvelope } from 'react-icons/fa6';

const teamMembers = [
  {
    name: 'Alexander Adolfsson',
    position: 'Founder & CEO',
    email: 'alexander.adolfsson@tabletoptippers.com',
    imageUrl:
      'https://media.licdn.com/dms/image/v2/D4D03AQGajoItD7IysA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1728370114463?e=1734566400&v=beta&t=Q2n1jsYoPnyzdTRG9Ak1JJ4Un2xPHC15WB_OBWo92Ho',
    description:
      'Alexander is the visionary behind Table Top Tippers, leading the company with a passion for gaming and community building. Is currently working as a software developer at Triggerbee as his 9 to 5.',
  },
];

const TeamPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/team-hero-bg.jpg)' }}
          aria-label="Team Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              Meet Our <span className="text-accent">Team</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl">
              Our dedicated team is what makes Table Top Tippers special.
            </p>
          </div>
        </section>

        {/* Team Members Section */}
        <section className="py-16 bg-white" aria-label="Team Members">
          <div className="container mx-auto px-4">
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {teamMembers.map((member, index) => (
                <div
                  key={index}
                  className="text-center bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                  <img
                    src={member.imageUrl}
                    alt={`Photo of ${member.name}`}
                    className="w-40 h-40 object-cover rounded-full mx-auto mb-4"
                  />
                  <h3 className="text-2xl font-semibold text-primary mb-2">{member.name}</h3>
                  <p className="text-lg text-gray-700 mb-2">{member.position}</p>
                  <p className="text-gray-700 mb-4">
                    <FaEnvelope className="inline-block text-accent mr-2" aria-hidden="true" />
                    <a href={`mailto:${member.email}`} className="text-accent hover:underline">
                      {member.email}
                    </a>
                  </p>
                  <p className="text-gray-700">{member.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default TeamPage;
