import React from 'react';
import { FaBullseye, FaEye } from 'react-icons/fa';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import logo from '../../../Assets/Images/AboutLogo.webp';

const AboutPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/about-hero-bg.jpg)' }}
          aria-label="About Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              About <span className="text-accent">Table Top Tippers</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl">
              We are passionate about bringing tabletop gaming to the forefront.
            </p>
          </div>
        </section>

        {/* About Content Section */}
        <section className="py-16 bg-white" aria-label="About Content">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="flex justify-center">
                <img src={logo} alt="Table Top Tippers Logo" className="max-w-full h-auto rounded shadow-md" />
              </div>
              <div>
                <h2 className="text-3xl font-semibold text-primary mb-4">
                  <FaBullseye className="inline-block text-accent mr-2" aria-hidden="true" />
                  Our Mission
                </h2>
                <p className="text-lg text-gray-700 mb-6">
                  At Table Top Tippers, our mission is to create a vibrant community of tabletop gaming enthusiasts. We
                  believe in the power of games to bring people together and create memorable experiences.
                </p>
                <h2 className="text-3xl font-semibold text-primary mb-4">
                  <FaEye className="inline-block text-accent mr-2" aria-hidden="true" />
                  Our Vision
                </h2>
                <p className="text-lg text-gray-700">
                  We envision a world where tabletop gaming is celebrated and accessible to everyone, from casual
                  players to hardcore enthusiasts. Our platform is designed to support this vision by providing
                  resources, events, and a community hub for all things tabletop.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default AboutPage;
