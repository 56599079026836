import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../Contexts/ApiProvider';
import { FaArrowLeft, FaSpinner, FaUserCircle } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainHeader from '../../Partials/Header/MainHeader';
import MainFooter from '../../Partials/Footer/MainFooter';
import { Triggerbee } from '../../../types/triggerbee';
import { useJwt } from '../../../Contexts/JwtProvider';

declare var triggerbee: Triggerbee;

const LoginPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  useEffect(() => {
    if (role !== null) {
      navigate('/');
    }
  });

  const { loginAsync } = apiContext;

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const success = await loginAsync(values);
      if (success) {
        try {
          if (triggerbee && typeof triggerbee.event === 'function' && typeof triggerbee.identify === 'function') {
            // Identify the user
            triggerbee.identify({
              email: values.email,
            });

            // Log the login event
            triggerbee.event({
              type: 'goal',
              name: 'Logged in',
              identity: {
                email: values.email,
              },
            });
          }
        } catch (error) {
          console.error('Triggerbee integration failed', error);
        } finally {
          navigate('/my-account');
        }
      } else {
        setErrorMessage('Login failed. Try again');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 flex flex-col items-center justify-center p-6 pt-20 bg-gray-50">
        <div className="self-start mb-6 cursor-pointer text-2xl text-gray-600" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>
        {/* User Icon */}
        <FaUserCircle className="text-6xl text-primary mb-4" />
        <h1 className="text-4xl font-bold text-primary mb-6 text-center">Login</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full max-w-md bg-white p-8 border border-gray-300 rounded-lg shadow-lg">
          <div className="mb-4">
            <label htmlFor="email" className="block text-lg font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-lg font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              name="password"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              name="rememberMe"
              className="mr-2 h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
              checked={formik.values.rememberMe}
              onChange={formik.handleChange}
            />
            <label htmlFor="rememberMe" className="text-gray-700">
              Remember Me
            </label>
          </div>
          <button
            type="submit"
            disabled={formik.isSubmitting}
            className={`w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300 ${
              formik.isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}>
            {formik.isSubmitting ? <FaSpinner className="animate-spin mx-auto" /> : 'Login'}
          </button>
          {errorMessage && <div className="mt-3 text-red-500 text-center">{errorMessage}</div>}
        </form>
        <div className="mt-6 flex flex-col items-center space-y-3">
          <p className="text-gray-700">Don't have an account?</p>
          <button
            className="w-full max-w-xs bg-secondary text-white py-2 px-4 rounded-md font-semibold hover:bg-secondary-dark transition-colors duration-300"
            onClick={() => navigate('/register')}>
            Register Here
          </button>
          <button
            className="w-full max-w-xs bg-secondary text-white py-2 px-4 rounded-md font-semibold hover:bg-secondary-dark transition-colors duration-300"
            onClick={() => navigate('/forgottenpassword')}>
            Forgot Your Password?
          </button>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default LoginPage;
