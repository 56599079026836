import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../Contexts/JwtProvider';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { RegisterSimpleTableRequest } from '../../../../types/apimodels';
import { Triggerbee } from '../../../../types/triggerbee';
import SimpleTableForm from '../../../Partials/SimpleTable/SimpleTableForm';

declare var triggerbee: Triggerbee;

const PublicNewSimpleTablePage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();

  const placeholderId = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');

  useEffect(() => {
    if (role !== null) {
      navigate('/my-account/tables/simpletables/createnew');
    }
  }, [role, navigate]);

  const initialFormData: RegisterSimpleTableRequest = {
    handlerId: placeholderId,
    name: '',
    numberOfTeams: 10,
    positiveAfterSeasonTeams: 0,
    negativeAfterSeasonTeams: 0,
    numberOfRelegations: 0,
    items: Array.from({ length: 10 }, (_, i) => ({
      name: '',
      tablePlace: i + 1,
    })),
    winnerColor: '#0f1990',
    positiveColor: '#2667b0',
    negativeColor: '#c55258',
    relegationColor: '#5d191c',
    itemColor: '#000000',
    isPublicTable: true,
  };

  const handleSubmit = async (formData: RegisterSimpleTableRequest, email?: string) => {
    setSubmissionError('');

    if (!apiContext) return;

    if (!email) {
      setSubmissionError('Email is required.');
      return;
    }

    setIsSubmitting(true);
    try {
      const responseUUID = await apiContext.publicRegisterSimpleTableAsync(formData, email);
      setIsSubmitting(false);
      if (responseUUID) {
        try {
          if (triggerbee && typeof triggerbee.event === 'function' && typeof triggerbee.identify === 'function') {
            // Identify the user
            triggerbee.identify({
              email: email,
            });

            // Log the event
            triggerbee.event({
              type: 'goal',
              name: 'Created simple table',
              identity: {
                email: email,
              },
            });
          }
        } catch (error) {
          console.error('Triggerbee integration failed', error);
        } finally {
          const cleanedUUID = responseUUID.replace(/"/g, '');
          navigate(`/simpletable/${cleanedUUID}`);
        }
      } else {
        setSubmissionError('Failed to create table. Please try again later.');
      }
    } catch (error) {
      setIsSubmitting(false);
      setSubmissionError('Failed to create table. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-bold mb-6">Create New Table</h1>
        <SimpleTableForm
          initialFormData={initialFormData}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          isPublic={true}
        />
      </div>
      <MainFooter />
    </div>
  );
};

export default PublicNewSimpleTablePage;
