import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const PrivacyPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 p-6 pt-10 container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold text-primary mb-6">Privacy Policy</h1>
          <p className="text-lg text-neutral mb-6">
            Your privacy is important to us. It is Table Top Tippers' policy to respect your privacy regarding any
            information we may collect from you across our website.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">1. Information We Collect</h2>
          <p className="text-neutral mb-4">
            We may collect personal information from you such as your name, email address, and contact details when you
            use our service.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">2. How We Use Information</h2>
          <p className="text-neutral mb-4">
            We use the information we collect to provide, maintain, and improve our services, to develop new services,
            and to protect Table Top Tippers and our users.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">3. Data Security</h2>
          <p className="text-neutral mb-4">
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
            disclosure, we have put in place suitable physical, electronic, and managerial procedures to safeguard and
            secure the information we collect online.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">4. Sharing of Information</h2>
          <p className="text-neutral mb-4">
            We do not share your personal information with third parties except to comply with the law, develop our
            products, or protect our rights.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">5. Your Rights</h2>
          <p className="text-neutral mb-4">
            You have the right to access, rectify, or delete your personal information at any time. You can do this by
            contacting us at{' '}
            <a href="mailto:info@tabletoptippers.com" className="text-accent hover:underline">
              info@tabletoptippers.com
            </a>
            .
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">6. Changes to This Policy</h2>
          <p className="text-neutral mb-4">
            We may update our privacy policy from time to time. We will notify you of any changes by posting the new
            privacy policy on this page.
          </p>
          <h2 className="text-2xl font-semibold text-primary mb-4">7. Contact Us</h2>
          <p className="text-neutral mb-4">
            If you have any questions about this privacy policy, please contact us at{' '}
            <a href="mailto:info@tabletoptippers.com" className="text-accent hover:underline">
              info@tabletoptippers.com
            </a>
            .
          </p>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default PrivacyPage;
