import React, { useContext, useEffect, useState, useRef } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import SimpleTable from '../../../Partials/SimpleTable/SimpleTable';
import { SimpleTableResponse } from '../../../../types/apimodels';
import { toast } from 'react-toastify';

const OfficialTablesPage: React.FC = () => {
  const clientUrl = process.env.REACT_APP_CLIENT_URL || window.location.origin;
  const apiContext = useContext(ApiContext);
  const [tables, setTables] = useState<SimpleTableResponse[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const effectRan = useRef(false); // Use ref to prevent double execution

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { getOfficialSimpleTablesAsync } = apiContext;

  useEffect(() => {
    if (effectRan.current === false) {
      effectRan.current = true; // Prevent future calls in development

      const fetchTables = async () => {
        try {
          const response = await getOfficialSimpleTablesAsync();
          if (response) {
            setTables(response);
          } else {
            setTables([]);
          }
        } catch (error) {
          console.error('Failed to fetch official tables:', error);
          toast.error('Failed to fetch official tables. Please try again later.');
          setTables([]);
        } finally {
          setLoading(false);
        }
      };

      fetchTables();
    }
  }, [getOfficialSimpleTablesAsync]);

  // Function to handle sharing the table URL
  const handleShare = async (tableId: string) => {
    const shareUrl = `${clientUrl}/simpletable/${tableId}`;
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Table URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy table URL. Please try again.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-20">
        {/* Headline and Description */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Official Tables</h1>
          <p className="text-lg text-gray-700">
            Explore the official tables curated by TableTopTippers. Feel free to challenge or get inspired by us.
          </p>
        </div>

        {loading ? (
          // Spinner
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary"></div>
          </div>
        ) : tables && tables.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {tables.map(table => (
              <div key={table.id} className="bg-white shadow rounded-lg p-4 relative">
                {/* Table Component */}
                <SimpleTable tableData={table} />
                {/* Created by */}
                <p className="mt-4 text-gray-700">Created by: Table Top Tippers</p>
                <p className="mt-2 text-gray-700">Views: {table.views}</p>
                {/* Share Button */}
                <button
                  onClick={() => handleShare(table.id)}
                  className="bg-primary hover:bg-primary-light text-white font-semibold py-2 px-4 rounded mt-4 w-full">
                  Share Table
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500">No official tables available at the moment.</p>
        )}
      </div>
      <MainFooter />
    </div>
  );
};

export default OfficialTablesPage;
