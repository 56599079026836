import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBriefcase, FaList } from 'react-icons/fa6';

const jobOpenings = [
  {
    title: 'UI/UX Designer',
    description:
      'We are looking for a creative and passionate UI/UX Designer to join our team. You will be responsible for designing engaging and user-friendly interfaces for our platform.',
    requirements: [
      'Proven experience as a UI/UX Designer or similar role',
      'Strong portfolio of design projects',
      'Familiarity with wireframe tools (e.g. Wireframe.cc, InVision)',
      'Up-to-date knowledge of design software like Adobe Illustrator and Photoshop',
    ],
  },
  {
    title: 'QA Engineer',
    description:
      'We are looking for a meticulous and dedicated QA Engineer to ensure the quality of our software products. You will be responsible for identifying and resolving issues before product launches.',
    requirements: [
      'Experience as a QA Engineer or similar role',
      'Familiarity with Agile frameworks and regression testing',
      'Excellent communication skills',
      'Attention to detail and analytical mind',
    ],
  },
  {
    title: 'Frontend Developer',
    description:
      'We are looking for a skilled Frontend Developer to join our development team. You will be responsible for building the client-side of our web applications.',
    requirements: [
      'Experience as a Frontend Developer or similar role',
      'Proficient in HTML, CSS, JavaScript, and modern frameworks like React',
      'Understanding of key design principles',
      'Experience with responsive and adaptive design',
    ],
  },
];

const CareerPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/careers-hero-bg.jpg)' }}
          aria-label="Careers Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4">
              Careers at <span className="text-accent">Table Top Tippers</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl">
              Join our team and help us build the future of tabletop gaming!
            </p>
          </div>
        </section>

        {/* Job Openings Section */}
        <section className="py-16 bg-white" aria-label="Job Openings">
          <div className="container mx-auto px-4">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-semibold text-primary mb-4">
                <FaBriefcase className="inline-block text-4xl text-accent mr-2" aria-hidden="true" />
                Current Openings
              </h2>
              <p className="text-lg text-gray-700">We're looking for talented individuals to join our growing team.</p>
            </div>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {jobOpenings.map((job, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                  <h3 className="text-2xl font-semibold text-primary mb-4">{job.title}</h3>
                  <p className="text-gray-700 mb-4">{job.description}</p>
                  <h4 className="text-lg font-semibold text-primary mb-2">
                    <FaList className="inline-block text-accent mr-2" aria-hidden="true" />
                    Requirements:
                  </h4>
                  <ul className="list-disc list-inside mb-4 text-gray-700">
                    {job.requirements.map((requirement, i) => (
                      <li key={i}>
                        <FaCheckCircle className="inline-block text-green-500 mr-2" aria-hidden="true" />
                        {requirement}
                      </li>
                    ))}
                  </ul>
                  <a
                    href="mailto:info@tabletoptippers.com"
                    className="bg-accent text-white py-2 px-4 rounded-full font-semibold hover:bg-accent-dark transition duration-300 inline-block">
                    Apply Now
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default CareerPage;
