import React from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import SimpleTable, { SimpleTableProps } from '../../../Partials/SimpleTable/SimpleTable';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { FaShareAlt, FaUserFriends } from 'react-icons/fa';
import { FaBullhorn, FaGlobe, FaLightbulb, FaTable } from 'react-icons/fa6';

const NewsPage: React.FC = () => {
  const navigate = useNavigate();
  const { role } = useJwt();

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  // Real-life example tables data
  const exampleTables: SimpleTableProps[] = [
    {
      name: 'NBA Playoff Teams',
      numberOfTeams: 16,
      positiveAfterSeasonTeams: 8,
      negativeAfterSeasonTeams: 0,
      numberOfRelegations: 0,
      items: [
        { name: 'Los Angeles Lakers', tablePlace: 1 },
        { name: 'Golden State Warriors', tablePlace: 2 },
        { name: 'Brooklyn Nets', tablePlace: 3 },
        { name: 'Milwaukee Bucks', tablePlace: 4 },
        { name: 'Phoenix Suns', tablePlace: 5 },
        { name: 'Philadelphia 76ers', tablePlace: 6 },
        { name: 'Dallas Mavericks', tablePlace: 7 },
        { name: 'Miami Heat', tablePlace: 8 },
        { name: 'Denver Nuggets', tablePlace: 9 },
        { name: 'Utah Jazz', tablePlace: 10 },
        { name: 'Chicago Bulls', tablePlace: 11 },
        { name: 'Atlanta Hawks', tablePlace: 12 },
        { name: 'Toronto Raptors', tablePlace: 13 },
        { name: 'Memphis Grizzlies', tablePlace: 14 },
        { name: 'Boston Celtics', tablePlace: 15 },
        { name: 'New York Knicks', tablePlace: 16 },
      ],
      winnerColor: '#4a4e69',
      positiveColor: '#9a8c98',
      negativeColor: '#f2e9e4',
      relegationColor: '#c9ada7',
      itemColor: '#000000',
    },
    {
      name: 'Bundesliga Standings',
      numberOfTeams: 18,
      positiveAfterSeasonTeams: 6,
      negativeAfterSeasonTeams: 1,
      numberOfRelegations: 2,
      items: [
        { name: 'Bayern Munich', tablePlace: 1 },
        { name: 'Borussia Dortmund', tablePlace: 2 },
        { name: 'RB Leipzig', tablePlace: 3 },
        { name: 'Bayer Leverkusen', tablePlace: 4 },
        { name: 'Eintracht Frankfurt', tablePlace: 5 },
        { name: 'SC Freiburg', tablePlace: 6 },
        { name: 'Union Berlin', tablePlace: 7 },
        { name: 'Hoffenheim', tablePlace: 8 },
        { name: 'Mainz', tablePlace: 9 },
        { name: 'Borussia Mönchengladbach', tablePlace: 10 },
        { name: 'Hertha Berlin', tablePlace: 11 },
        { name: 'Wolfsburg', tablePlace: 12 },
        { name: 'Bochum', tablePlace: 13 },
        { name: 'Augsburg', tablePlace: 14 },
        { name: 'Stuttgart', tablePlace: 15 },
        { name: 'Arminia Bielefeld', tablePlace: 16 },
        { name: 'Schalke 04', tablePlace: 17 },
        { name: 'Greuther Fürth', tablePlace: 18 },
      ],
      winnerColor: '#e9c46a',
      positiveColor: '#f4a261',
      negativeColor: '#e76f51',
      relegationColor: '#264653',
      itemColor: '#000000',
    },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <main className="flex-1">
        {/* Hero Section */}
        <section
          className="relative bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/news-hero-bg.jpg)' }}
          aria-label="Hero Section">
          <div className="absolute inset-0 bg-black opacity-60"></div>
          <div className="relative text-center py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-5xl font-extrabold text-white mb-4 animate-fade-in">
              Latest News from <span className="text-accent">Table Top Tippers</span>
            </h1>
            <p className="text-xl text-gray-200 mx-auto max-w-2xl animate-fade-in">
              Stay updated with the latest developments, features, and announcements.
            </p>
          </div>
        </section>

        {/* New Friends Feature Section */}
        <section className="py-16 bg-gray-100" aria-label="Friends Feature Announcement">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <FaUserFriends className="text-accent text-4xl mr-2 animate-bounce" />
                  Connect with Friends
                </h2>
                <p className="text-lg text-gray-700 mb-4">
                  We're thrilled to introduce the ability to add and remove friends on Table Top Tippers!
                </p>
                <p className="text-lg text-gray-700 mb-4">
                  Build your own community, share your passion, and compete with your friends.
                </p>
                <p className="text-lg text-gray-700 mb-6">
                  <strong>Coming Soon:</strong> You'll soon be able to inspect your friends' tables and send direct
                  messages to them. Stay tuned for more updates!
                </p>
                <div className="flex justify-center md:justify-start">
                  <button
                    className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                    onClick={() => handleNavigate(role === null ? '/register' : '/my-account/profile/friends')}>
                    {role === null ? 'Connect Now' : 'Go to Your Friends'}
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                {/* Illustration or Image */}
                <FaUserFriends className="text-accent text-9xl animate-pulse" aria-hidden="true" />
              </div>
            </div>
          </div>
        </section>

        {/* Share Your Table Section */}
        <section className="py-16 bg-white" aria-label="Share Your Table">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
                <h2 className="text-3xl font-semibold mb-4 flex items-center">
                  <FaShareAlt className="text-accent text-4xl mr-2 animate-pulse" />
                  Share Your Own Table
                </h2>
                <p className="text-lg text-gray-700 mb-4">
                  We've made it easier than ever to share your custom tables with friends and on social media!
                </p>
                <p className="text-lg text-gray-700 mb-4">
                  Create a table, set it to public, and use the share button to spread the word.
                </p>
                <p className="text-lg text-gray-700 mb-6">
                  Private tables remain accessible only to you, ensuring your predictions stay confidential until you're
                  ready to share.
                </p>
                <div className="flex justify-center md:justify-start">
                  <button
                    className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
                    onClick={() => handleNavigate(role === null ? '/register' : '/my-account/tables')}>
                    {role === null ? 'Start Sharing' : 'Go to Your Tables'}
                  </button>
                </div>
              </div>
              <div className="md:w-1/2 flex justify-center">
                {/* Illustration or Image */}
                <FaTable className="text-accent text-9xl animate-bounce" aria-hidden="true" />
              </div>
            </div>
          </div>
        </section>

        {/* Official Tables Section */}
        <section className="py-16 bg-gray-100" aria-label="Official Tables">
          <div className="container mx-auto px-4">
            <div className="text-center mb-2">
              <h2 className="text-3xl font-semibold mb-4 flex items-center justify-center">
                <FaGlobe className="text-accent text-4xl mr-2 animate-spin" />
                Official Tables from TTT
              </h2>
              <p className="text-lg text-gray-700 mb-4">
                Check out our official tables and see our predictions for the current season!
              </p>
              <p className="text-lg text-gray-700 mb-4">
                Use them as inspiration or challenge us with your own predictions.
              </p>
              <button
                className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105 mt-6"
                onClick={() => handleNavigate('/official-tables')}>
                See Official Tables
              </button>
            </div>
          </div>
        </section>

        {/* Simple Tables Section */}
        <section className="bg-white py-8" aria-label="Simple Tables">
          <h2 className="text-3xl font-semibold text-center mb-8">Explore Our Simple Tables</h2>
          <p className="text-lg text-center mb-8 mx-4">
            We have released Simple Tables in an alpha state! Check out these real-life examples below.
          </p>
          <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 px-4">
            {exampleTables.map((table, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                <SimpleTable tableData={table} />
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button
              className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
              onClick={() => handleNavigate(role === null ? '/register' : '/my-account/tables')}>
              {role === null ? 'Sign Up and Create Your Own Table' : 'Create Your Table Here'}
            </button>
          </div>
        </section>

        {/* Conditionally Render Sign Up and News Sections */}
        {role === null && (
          <>
            {/* Sign Up Section */}
            <section
              className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-16"
              aria-label="Sign Up Section">
              <div className="container mx-auto px-4 text-center">
                <h2 className="text-4xl font-semibold mb-6">Join Us Today!</h2>
                <p className="text-lg mb-8">
                  Want to create your own table and see how your predictions stack up against your friends? Sign up now
                  and start customizing your own tables before the new season kicks off!
                </p>
                <button
                  className="bg-white text-primary py-3 px-8 rounded-full font-semibold hover:bg-gray-100 transition duration-300 transform hover:scale-105"
                  onClick={() => handleNavigate('/register')}>
                  Sign Up Now
                </button>
              </div>
            </section>

            {/* News & Updates Section */}
            <section className="bg-white py-16" aria-label="News & Updates">
              <h2 className="text-3xl font-semibold text-center mb-12">News & Updates</h2>
              <div className="container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 px-4">
                {/* News Card 1 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                  <div className="flex items-center mb-4">
                    <FaBullhorn className="text-primary text-3xl mr-3" />
                    <h3 className="text-2xl font-semibold">We Are Developing Our Service!</h3>
                  </div>
                  <p className="text-gray-700 mb-4">
                    We're excited to announce that we are fully developing our platform and aim to have it beta-ready
                    before the start of the Allsvenskan and Superettan 2025 season.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="bg-primary text-white py-2 px-6 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
                      onClick={() => handleNavigate('/register')}>
                      Join Alpha Testing
                    </button>
                  </div>
                </div>
                {/* News Card 2 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                  <div className="flex items-center mb-4">
                    <FaUserFriends className="text-primary text-3xl mr-3" />
                    <h3 className="text-2xl font-semibold">Become a Power User</h3>
                  </div>
                  <p className="text-gray-700 mb-4">
                    Participate in our alpha testing phase to gain power user status once the platform is live. Enjoy
                    exclusive benefits and early access to new features.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="bg-primary text-white py-2 px-6 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
                      onClick={() => handleNavigate('/register')}>
                      Sign Up Now
                    </button>
                  </div>
                </div>
                {/* News Card 3 */}
                <div className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:-translate-y-2">
                  <div className="flex items-center mb-4">
                    <FaLightbulb className="text-primary text-3xl mr-3" />
                    <h3 className="text-2xl font-semibold">Feature Highlights</h3>
                  </div>
                  <p className="text-gray-700 mb-4">
                    We're adding new features regularly based on community feedback. Stay tuned for more exciting
                    developments as we continue to enhance your experience.
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="bg-primary text-white py-2 px-6 rounded-full font-semibold hover:bg-primary-dark transition duration-300"
                      onClick={() => handleNavigate('/features')}>
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        {/* Community Feedback Section */}
        <section className="bg-gray-100 py-16" aria-label="Community Feedback">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-semibold mb-6">We Value Your Feedback</h2>
            <p className="text-lg text-gray-700 mb-8">
              Your feedback is crucial to us. We're committed to making the best platform for sports enthusiasts.
            </p>
            <p className="text-lg text-gray-700 mb-8">
              If you have suggestions, please contact us at{' '}
              <a href="mailto:info@tabletoptippers.com" className="text-blue-500 hover:underline">
                info@tabletoptippers.com
              </a>
              .
            </p>
            <button
              className="bg-accent text-white py-3 px-8 rounded-full font-semibold hover:bg-accent-dark transition duration-300 transform hover:scale-105"
              onClick={() => handleNavigate('/feedback')}>
              Give Feedback
            </button>
          </div>
        </section>
      </main>
      <MainFooter />
    </div>
  );
};

export default NewsPage;
