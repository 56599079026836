export enum Country {
  NoCountry,
  Afghanistan,
  Albania,
  Algeria,
  Andorra,
  Angola,
  AntiguaAndBarbuda,
  Argentina,
  Armenia,
  Australia,
  Austria,
  Azerbaijan,
  Bahamas,
  Bahrain,
  Bangladesh,
  Barbados,
  Belarus,
  Belgium,
  Belize,
  Benin,
  Bhutan,
  Bolivia,
  BosniaAndHerzegovina,
  Botswana,
  Brazil,
  Brunei,
  Bulgaria,
  BurkinaFaso,
  Burundi,
  CaboVerde,
  Cambodia,
  Cameroon,
  Canada,
  CentralAfricanRepublic,
  Chad,
  Chile,
  China,
  Colombia,
  Comoros,
  CongoBrazzaville,
  CongoKinshasa,
  CostaRica,
  Croatia,
  Cuba,
  Cyprus,
  CzechRepublic,
  Denmark,
  Djibouti,
  Dominica,
  DominicanRepublic,
  EastTimor,
  Ecuador,
  Egypt,
  ElSalvador,
  EquatorialGuinea,
  Eritrea,
  Estonia,
  Eswatini,
  Ethiopia,
  Fiji,
  Finland,
  France,
  Gabon,
  Gambia,
  Georgia,
  Germany,
  Ghana,
  Greece,
  Grenada,
  Guatemala,
  Guinea,
  GuineaBissau,
  Guyana,
  Haiti,
  Honduras,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iran,
  Iraq,
  Ireland,
  Israel,
  Italy,
  IvoryCoast,
  Jamaica,
  Japan,
  Jordan,
  Kazakhstan,
  Kenya,
  Kiribati,
  KoreaNorth,
  KoreaSouth,
  Kosovo,
  Kuwait,
  Kyrgyzstan,
  Laos,
  Latvia,
  Lebanon,
  Lesotho,
  Liberia,
  Libya,
  Liechtenstein,
  Lithuania,
  Luxembourg,
  Madagascar,
  Malawi,
  Malaysia,
  Maldives,
  Mali,
  Malta,
  MarshallIslands,
  Mauritania,
  Mauritius,
  Mexico,
  Micronesia,
  Moldova,
  Monaco,
  Mongolia,
  Montenegro,
  Morocco,
  Mozambique,
  Myanmar,
  Namibia,
  Nauru,
  Nepal,
  Netherlands,
  NewZealand,
  Nicaragua,
  Niger,
  Nigeria,
  NorthMacedonia,
  Norway,
  Oman,
  Pakistan,
  Palau,
  Panama,
  PapuaNewGuinea,
  Paraguay,
  Peru,
  Philippines,
  Poland,
  Portugal,
  Qatar,
  Romania,
  Russia,
  Rwanda,
  SaintKittsAndNevis,
  SaintLucia,
  SaintVincentAndTheGrenadines,
  Samoa,
  SanMarino,
  SaoTomeAndPrincipe,
  SaudiArabia,
  Senegal,
  Serbia,
  Seychelles,
  SierraLeone,
  Singapore,
  Slovakia,
  Slovenia,
  SolomonIslands,
  Somalia,
  SouthAfrica,
  SouthSudan,
  Spain,
  SriLanka,
  Sudan,
  Suriname,
  Sweden,
  Switzerland,
  Syria,
  Taiwan,
  Tajikistan,
  Tanzania,
  Thailand,
  Togo,
  Tonga,
  TrinidadAndTobago,
  Tunisia,
  Turkey,
  Turkmenistan,
  Tuvalu,
  Uganda,
  Ukraine,
  UnitedArabEmirates,
  UnitedKingdom,
  England,
  Scotland,
  Wales,
  NorthernIreland,
  UnitedStates,
  Uruguay,
  Uzbekistan,
  Vanuatu,
  VaticanCity,
  Venezuela,
  Vietnam,
  Yemen,
  Zambia,
  Zimbabwe,

  // Minor Nations
  Abkhazia,
  Artsakh,
  CookIslands,
  Niue,
  Palestine,
  SahrawiArabDemocraticRepublic,
  TaiwanRepublicOfChina,
  Transnistria,
  Somaliland,
  TurkishRepublicOfNorthernCyprus,
  KosovoUnrecognizedBySeveralCountries,
  WesternSahara,
  NorthernCyprus,
}

export enum Season {
  Season2023 = 23,
  Season20232024 = 2324,
  Season2024 = 24,
  Season20242025 = 2425,
  Season2025 = 25,
  Season20252026 = 2526,
}
