import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { FaCheckCircle } from 'react-icons/fa';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

const RoadmapPage: React.FC = () => {
  const navigate = useNavigate();
  const [showAllMilestones, setShowAllMilestones] = useState<boolean>(false);

  // Refs to sections
  const simpleLeaguesRef = useRef<HTMLDivElement>(null);
  const simpleTablesRef = useRef<HTMLDivElement>(null);
  const advancedLeaguesRef = useRef<HTMLDivElement>(null);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const milestones = [
    { date: 'September 2024', description: 'Release of [Simple Tables](#simple-tables).', completed: true },
    { date: 'October 2024', description: 'Improvements to [Simple Tables](#simple-tables).', completed: true },
    { date: 'October 2024', description: 'Adding and removing friends.', completed: true },
    {
      date: 'October 2024',
      description: 'Inspecting friends profiles and tables.',
      completed: false,
    },
    { date: 'November 2024', description: 'Sending PMs to friends', completed: false },
    {
      date: 'December 2024',
      description: 'Alpha release of [Simple Leagues](#simple-leagues) for Allsvenskan and Superettan.',
      completed: false,
    },
    {
      date: 'January 2025',
      description: 'Beta release of [Simple Leagues](#simple-leagues) for Allsvenskan and Superettan.',
      completed: false,
    },
    {
      date: 'February 2025',
      description: 'Alpha release of [Advanced Leagues](#advanced-leagues) for Allsvenskan and Superettan.',
      completed: false,
    },
    {
      date: 'March 2025',
      description: 'Beta release of [Advanced Leagues](#advanced-leagues) for Allsvenskan and Superettan.',
      completed: false,
    },
    {
      date: 'May 2025',
      description: 'Launch of Simple and Advanced Leagues for Bundesliga, Premier League, and Serie A.',
      completed: false,
    },
    { date: 'August 2025', description: 'Version 1.0 release including hockey leagues.', completed: false },
  ];

  // Separate completed and upcoming milestones
  const completedMilestones = milestones.filter(item => item.completed);
  const upcomingMilestones = milestones.filter(item => !item.completed);

  // Latest completed milestones to show on the main page
  const latestCompletedMilestones = completedMilestones.slice(-2);

  // Function to render milestone descriptions with clickable links
  const renderDescription = (description: string) => {
    const linkMappings: { [key: string]: React.RefObject<HTMLDivElement> } = {
      '[Simple Tables](#simple-tables)': simpleTablesRef,
      '[Simple Leagues](#simple-leagues)': simpleLeaguesRef,
      '[Advanced Leagues](#advanced-leagues)': advancedLeaguesRef,
    };

    // Replace markdown-style links with actual clickable spans
    let parts = description.split(/(\[.*?\]\(#.*?\))/g);

    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\(#(.*?)\)/);
      if (match) {
        const text = match[1];
        const anchor = match[2];
        const ref = linkMappings[`[${text}](#${anchor})`];
        return (
          <span
            key={index}
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={() => scrollToSection(ref)}>
            {text}
          </span>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <MainHeader />
      <main className="flex-1 p-6 pt-20 container mx-auto max-w-4xl">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-primary mb-4">Product Roadmap</h1>
          <p className="text-lg text-gray-700">Explore our development timeline and upcoming features.</p>
          <p className="text-lg text-gray-500">
            Please note that these dates and features are subject to change as we continue to refine our product.
          </p>
        </header>

        {/* Upcoming Milestones */}
        <section className="relative pl-6 md:pl-10 mb-12" aria-label="Upcoming Milestones">
          <div className="absolute left-2 md:left-4 top-0 h-full border-l-4 border-primary" aria-hidden="true"></div>
          <div className="space-y-12">
            {upcomingMilestones.map((item, index) => (
              <article key={index} className="relative pl-8 md:pl-10">
                {/* Icon */}
                <div className="absolute left-0 top-2.5 h-6 w-6 rounded-full flex items-center justify-center bg-primary">
                  {/* Empty circle for upcoming milestones */}
                </div>

                {/* Date and Description */}
                <h2 className="text-2xl font-semibold mb-2 text-primary">{item.date}</h2>
                <p className="text-lg text-gray-700">{renderDescription(item.description)}</p>
              </article>
            ))}
          </div>
        </section>

        {/* Latest Completed Milestones */}
        <section className="relative pl-6 md:pl-10 mb-12" aria-label="Latest Completed Milestones">
          <div className="absolute left-2 md:left-4 top-0 h-full border-l-4 border-green-500" aria-hidden="true"></div>
          <h2 className="text-3xl font-semibold text-green-500 mb-6">Recently Completed</h2>
          <div className="space-y-12">
            {latestCompletedMilestones.map((item, index) => (
              <article key={index} className="relative pl-8 md:pl-10">
                {/* Icon */}
                <div className="absolute left-0 top-2.5 h-6 w-6 rounded-full flex items-center justify-center bg-green-500">
                  <FaCheckCircle className="text-white" aria-hidden="true" />
                </div>

                {/* Date and Description */}
                <h2 className="text-2xl font-semibold mb-2 text-green-500">{item.date}</h2>
                <p className="text-lg text-gray-700">{renderDescription(item.description)}</p>
              </article>
            ))}
          </div>
        </section>

        {/* View All Milestones Button */}
        <div className="text-center mb-12">
          <button
            className="bg-primary text-white py-2 px-6 rounded-full font-semibold hover:bg-primary-dark transition duration-300 flex items-center justify-center mx-auto"
            onClick={() => setShowAllMilestones(!showAllMilestones)}
            aria-expanded={showAllMilestones}
            aria-controls="all-milestones">
            {showAllMilestones ? (
              <>
                Hide All Milestones <FaChevronUp className="ml-2" />
              </>
            ) : (
              <>
                View All Milestones <FaChevronDown className="ml-2" />
              </>
            )}
          </button>
        </div>

        {/* All Completed Milestones */}
        {showAllMilestones && (
          <section id="all-milestones" className="relative pl-6 md:pl-10 mb-12" aria-label="All Completed Milestones">
            <div
              className="absolute left-2 md:left-4 top-0 h-full border-l-4 border-green-500"
              aria-hidden="true"></div>
            <h2 className="text-3xl font-semibold text-green-500 mb-6">All Completed Milestones</h2>
            <div className="space-y-12">
              {completedMilestones.map((item, index) => (
                <article key={index} className="relative pl-8 md:pl-10">
                  {/* Icon */}
                  <div className="absolute left-0 top-2.5 h-6 w-6 rounded-full flex items-center justify-center bg-green-500">
                    <FaCheckCircle className="text-white" aria-hidden="true" />
                  </div>

                  {/* Date and Description */}
                  <h2 className="text-2xl font-semibold mb-2 text-green-500">{item.date}</h2>
                  <p className="text-lg text-gray-700">{renderDescription(item.description)}</p>
                </article>
              ))}
            </div>
          </section>
        )}

        {/* Simple Tables Section */}
        <section
          id="simple-tables"
          ref={simpleTablesRef}
          className="mb-12 scroll-mt-20"
          aria-label="Simple Tables Explanation">
          <h2 className="text-3xl font-semibold text-primary mb-4">What Are Simple Tables?</h2>
          <p className="text-lg text-gray-700">
            Simple Tables are a way to create and share basic ranking tables for your favorite sports (or anything
            really) leagues.
          </p>
        </section>

        {/* Simple Leagues Section */}
        <section
          id="simple-leagues"
          ref={simpleLeaguesRef}
          className="mb-12 scroll-mt-20"
          aria-label="Simple Leagues Explanation">
          <h2 className="text-3xl font-semibold text-primary mb-4">What Are Simple Leagues?</h2>
          <p className="text-lg text-gray-700">
            The details of this is currently being worked on, we will write it here as soon as we are sure.
          </p>
        </section>

        {/* Advanced Leagues Section */}
        <section
          id="advanced-leagues"
          ref={advancedLeaguesRef}
          className="mb-12 scroll-mt-20"
          aria-label="Advanced Leagues Explanation">
          <h2 className="text-3xl font-semibold text-primary mb-4">What Are Advanced Leagues?</h2>
          <p className="text-lg text-gray-700">
            The details of this is currently being worked on, we will write it here as soon as we are sure.
          </p>
        </section>

        <footer className="text-center mt-12">
          <h2 className="text-3xl font-semibold text-primary mb-4">We Value Your Feedback</h2>
          <p className="text-lg text-gray-700">
            If you have any feedback or suggestions, please feel free to contact us at{' '}
            <a href="mailto:info@tabletoptippers.com" className="text-blue-500 hover:underline">
              info@tabletoptippers.com
            </a>
          </p>
        </footer>
      </main>
      <MainFooter />
    </div>
  );
};

export default RoadmapPage;
