import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { UpdateUserRequest } from '../../../../../types/apimodels';

const AdminUpdateUserPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { adminUpdateUserAsync } = apiContext;

  const validationSchema = Yup.object({
    newFirstName: Yup.string().nullable(),
    newLastName: Yup.string().nullable(),
    newEmail: Yup.string().email('Invalid email format').nullable(),
    newPassword: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        'Password must contain an uppercase letter, a lowercase letter, a number, and a special character'
      )
      .nullable(),
    newPhoneNumber: Yup.string()
      .matches(/^\+\d{1,3}\d{9}$/, 'Not a valid phone number')
      .nullable(),
    newRole: Yup.string().nullable(),
    newImageUrl: Yup.string().url('Invalid URL format').nullable(),
  });

  const formik = useFormik({
    initialValues: {
      newFirstName: '',
      newLastName: '',
      newEmail: '',
      newPassword: '',
      newPhoneNumber: '',
      newRole: '',
      newImageUrl: '',
      newIsPublicProfile: false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const updateUserRequest: UpdateUserRequest = {
        newFirstName: values.newFirstName || null,
        newLastName: values.newLastName || null,
        newEmail: values.newEmail || null,
        newPassword: values.newPassword || null,
        newPhoneNumber: values.newPhoneNumber || null,
        newRole: values.newRole || null,
        currentPassword: null,
        newImageUrl: values.newImageUrl || null,
        isPublicProfile: values.newIsPublicProfile || false,
      };
      const success = await adminUpdateUserAsync(userId!, updateUserRequest);
      if (success) {
        alert('Update successful!');
        navigate('/adminhandleusers');
      } else {
        alert('Update failed.');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <div className="flex items-center mb-4">
          <button className="text-primary hover:text-primary-dark mb-4 self-start" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-semibold text-center flex-grow">Update User</h1>
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
          <p className="text-center mb-6 text-gray-600">
            Only enter the form fields that you want to update, leave the others empty.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label htmlFor="newFirstName" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                name="newFirstName"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newFirstName && formik.errors.newFirstName ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newFirstName}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newLastName" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                name="newLastName"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newLastName && formik.errors.newLastName ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newLastName}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="newEmail"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newEmail && formik.errors.newEmail ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newEmail}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                name="newPassword"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newPassword && formik.errors.newPassword ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newPassword}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newPhoneNumber" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="text"
                name="newPhoneNumber"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newPhoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newPhoneNumber && formik.errors.newPhoneNumber ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newPhoneNumber}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newRole" className="block text-sm font-medium text-gray-700">
                Role
              </label>
              <input
                type="text"
                name="newRole"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newRole}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newRole && formik.errors.newRole ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newRole}</div>
              ) : null}
            </div>
            <div className="mb-4">
              <label htmlFor="newImageUrl" className="block text-sm font-medium text-gray-700">
                Profile Image URL
              </label>
              <input
                type="text"
                name="newImageUrl"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={formik.values.newImageUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.newImageUrl && formik.errors.newImageUrl ? (
                <div className="text-red-500 text-sm mt-1">{formik.errors.newImageUrl}</div>
              ) : null}
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Update
            </button>
          </form>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminUpdateUserPage;
