import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useJwt } from '../../../../Contexts/JwtProvider';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { UUID } from 'crypto';
import { FriendRequestModel, MyFriendRequestsResponse, FriendResponse } from '../../../../types/apimodels';
import { FaArrowLeft, FaCheck, FaTimes, FaTrash, FaUserPlus, FaUserFriends, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';

const MyFriendsPage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role } = useJwt();
  const navigate = useNavigate();
  const [friendRequests, setFriendRequests] = useState<MyFriendRequestsResponse | null>(null);
  const [friendRequestsLoading, setFriendRequestsLoading] = useState<boolean>(true);
  const [friends, setFriends] = useState<FriendResponse[] | null>(null);
  const [friendsLoading, setFriendsLoading] = useState<boolean>(true);
  const [friendId, setFriendId] = useState<string>('');

  // Loading states for buttons
  const [isSendingFriendRequest, setIsSendingFriendRequest] = useState<boolean>(false);
  const [loadingRequestIds, setLoadingRequestIds] = useState<{ [key: string]: boolean }>({});
  const [loadingFriendIds, setLoadingFriendIds] = useState<{ [key: string]: boolean }>({});

  const isFriendRequestsFetched = useRef(false);
  const isFriendsFetched = useRef(false);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const {
    getMyFriendRequestsAsync,
    acceptFriendRequestAsync,
    deleteFriendRequestAsync,
    sendFriendRequestAsync,
    getMyFriendsAsync,
    deleteFriendAsync, // Added deleteFriendAsync to destructuring
  } = apiContext;

  // Fetch friend requests
  const fetchFriendRequests = useCallback(async () => {
    if (isFriendRequestsFetched.current) return;
    isFriendRequestsFetched.current = true;

    setFriendRequestsLoading(true);
    try {
      const response = await getMyFriendRequestsAsync();
      setFriendRequests(response);
    } catch (error) {
      console.error('Failed to fetch friend requests:', error);
      setFriendRequests(null);
    } finally {
      setFriendRequestsLoading(false);
    }
  }, [getMyFriendRequestsAsync]);

  // Fetch friends list
  const fetchFriends = useCallback(async () => {
    if (isFriendsFetched.current) return;
    isFriendsFetched.current = true;

    setFriendsLoading(true);
    try {
      const response = await getMyFriendsAsync();
      setFriends(response);
    } catch (error) {
      console.error('Failed to fetch friends:', error);
      setFriends(null);
    } finally {
      setFriendsLoading(false);
    }
  }, [getMyFriendsAsync]);

  // Initial data fetch
  useEffect(() => {
    if (role === null) {
      navigate('/notauthorized');
    } else {
      fetchFriendRequests();
      fetchFriends();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, navigate]);

  // Handle accepting a friend request
  const handleAcceptRequest = async (requestId: UUID, friendId: string) => {
    setLoadingRequestIds(prev => ({ ...prev, [requestId]: true }));
    const success = await acceptFriendRequestAsync(friendId);
    setLoadingRequestIds(prev => ({ ...prev, [requestId]: false }));
    if (success) {
      toast.success('Friend request accepted');
      isFriendRequestsFetched.current = false;
      isFriendsFetched.current = false;
      await fetchFriendRequests();
      await fetchFriends();
    } else {
      toast.error('Failed to accept friend request');
    }
  };

  // Handle deleting a friend request
  const handleDeleteRequest = async (requestId: UUID) => {
    setLoadingRequestIds(prev => ({ ...prev, [requestId]: true }));
    const success = await deleteFriendRequestAsync(requestId);
    setLoadingRequestIds(prev => ({ ...prev, [requestId]: false }));
    if (success) {
      toast.success('Friend request deleted');
      isFriendRequestsFetched.current = false;
      await fetchFriendRequests();
    } else {
      toast.error('Failed to delete friend request');
    }
  };

  // Handle sending a friend request
  const handleSendFriendRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSendingFriendRequest(true);
    const success = await sendFriendRequestAsync(friendId);
    setIsSendingFriendRequest(false);
    if (success) {
      setFriendId('');
      toast.success('Friend request sent');
      isFriendRequestsFetched.current = false;
      await fetchFriendRequests();
    } else {
      toast.error('Failed to send friend request');
    }
  };

  // Handle removing a friend
  const handleRemoveFriend = async (friendUserId: string) => {
    setLoadingFriendIds(prev => ({ ...prev, [friendUserId]: true }));
    const success = await deleteFriendAsync(friendUserId);
    setLoadingFriendIds(prev => ({ ...prev, [friendUserId]: false }));
    if (success) {
      toast.success('Friend removed');
      isFriendsFetched.current = false;
      await fetchFriends();
    } else {
      toast.error('Something went wrong, try again later');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-6 py-8 pt-20">
        {/* Back Button */}
        <div className="flex items-center mb-6 cursor-pointer" onClick={() => navigate(-1)}>
          <FaArrowLeft className="text-xl text-primary mr-2" />
          <span className="text-xl text-primary">Back</span>
        </div>

        {/* Friends Header */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-primary flex items-center justify-center">
            <FaUserFriends className="mr-2" /> Friends
          </h2>
        </div>

        {/* Friends List */}
        {friendsLoading ? (
          // Spinner for loading friends
          <div className="flex justify-center items-center h-32">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-primary"></div>
          </div>
        ) : friends && friends.length > 0 ? (
          friends.map(friend => (
            <div
              key={friend.userId}
              className="flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md mb-4">
              <div className="flex items-center">
                <FaUserFriends className="text-primary mr-3" />
                <p className="text-lg text-neutral">{friend.fullName}</p>
              </div>
              <button
                className="bg-red-500 text-white py-1 px-3 rounded-md font-semibold hover:bg-red-600 transition-colors duration-300 flex items-center"
                onClick={() => handleRemoveFriend(friend.userId)}
                disabled={loadingFriendIds[friend.userId]}>
                {loadingFriendIds[friend.userId] ? (
                  <FaSpinner className="animate-spin mr-1" />
                ) : (
                  <FaTrash className="mr-1" />
                )}
                Remove Friend
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-lg text-neutral">You have no friends yet.</p>
        )}

        {/* Send Friend Request */}
        <div className="flex flex-col items-center mb-8 mt-8">
          <h2 className="text-2xl font-semibold text-neutral mb-4 flex items-center">
            <FaUserPlus className="mr-2" /> Send Friend Request
          </h2>
          <form onSubmit={handleSendFriendRequest} className="w-full max-w-md">
            <div className="relative">
              <input
                type="text"
                value={friendId}
                onChange={e => setFriendId(e.target.value)}
                placeholder="ID or email of the user"
                className="w-full mb-2 p-2 pl-10 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                required
              />
              <FaUserPlus className="absolute left-3 top-3 text-gray-400" />
            </div>
            <button
              type="submit"
              disabled={isSendingFriendRequest}
              className={`w-full bg-primary text-white py-2 px-4 rounded-md font-semibold hover:bg-primary-dark transition-colors duration-300 flex items-center justify-center ${
                isSendingFriendRequest ? 'opacity-50 cursor-not-allowed' : ''
              }`}>
              {isSendingFriendRequest ? <FaSpinner className="animate-spin mr-2" /> : <FaUserPlus className="mr-2" />}
              Send Friend Request
            </button>
          </form>
        </div>

        {/* Received Friend Requests */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-neutral mb-4 text-center">Received Friend Requests</h2>
          {friendRequestsLoading ? (
            // Spinner
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-primary"></div>
            </div>
          ) : friendRequests?.receivedRequests.length ? (
            friendRequests.receivedRequests.map((request: FriendRequestModel) => (
              <div
                key={request.id}
                className="flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md mb-4">
                <p className="text-lg text-neutral">
                  {request.senderProfile.name} ({request.senderProfile.email})
                </p>
                <div className="flex gap-4">
                  <button
                    className="bg-green-500 text-white py-1 px-3 rounded-md font-semibold hover:bg-green-600 transition-colors duration-300 flex items-center"
                    onClick={() => handleAcceptRequest(request.id, request.senderId)}
                    disabled={loadingRequestIds[request.id]}>
                    {loadingRequestIds[request.id] ? (
                      <FaSpinner className="animate-spin mr-1" />
                    ) : (
                      <FaCheck className="mr-1" />
                    )}
                    Accept
                  </button>
                  <button
                    className="bg-red-500 text-white py-1 px-3 rounded-md font-semibold hover:bg-red-600 transition-colors duration-300 flex items-center"
                    onClick={() => handleDeleteRequest(request.id)}
                    disabled={loadingRequestIds[request.id]}>
                    {loadingRequestIds[request.id] ? (
                      <FaSpinner className="animate-spin mr-1" />
                    ) : (
                      <FaTimes className="mr-1" />
                    )}
                    Decline
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-lg text-neutral">No received friend requests</p>
          )}
        </div>

        {/* Sent Friend Requests */}
        <div>
          <h2 className="text-2xl font-semibold text-neutral mb-4 text-center">Sent Friend Requests</h2>
          {friendRequestsLoading ? (
            // Spinner
            <div className="flex justify-center items-center h-32">
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-primary"></div>
            </div>
          ) : friendRequests?.sentRequests.length ? (
            friendRequests.sentRequests.map((request: FriendRequestModel) => (
              <div
                key={request.id}
                className="flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md mb-4">
                <p className="text-lg text-neutral">
                  {request.receiverProfile.name} ({request.receiverProfile.email})
                </p>
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded-md font-semibold hover:bg-red-600 transition-colors duration-300 flex items-center"
                  onClick={() => handleDeleteRequest(request.id)}
                  disabled={loadingRequestIds[request.id]}>
                  {loadingRequestIds[request.id] ? (
                    <FaSpinner className="animate-spin mr-1" />
                  ) : (
                    <FaTrash className="mr-1" />
                  )}
                  Cancel Request
                </button>
              </div>
            ))
          ) : (
            <p className="text-center text-lg text-neutral">No sent friend requests</p>
          )}
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default MyFriendsPage;
