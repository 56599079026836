import React from 'react';
import { RegisterSimpleTableItem } from '../../../types/apimodels';

export interface SimpleTableProps {
  name: string;
  numberOfTeams: number;
  positiveAfterSeasonTeams: number;
  negativeAfterSeasonTeams: number;
  numberOfRelegations: number;
  items: RegisterSimpleTableItem[];
  winnerColor: string;
  positiveColor: string;
  negativeColor: string;
  relegationColor: string;
  itemColor: string;
}

const SimpleTable: React.FC<{ tableData: SimpleTableProps }> = ({ tableData }) => {
  const {
    name,
    positiveAfterSeasonTeams,
    negativeAfterSeasonTeams,
    numberOfRelegations,
    winnerColor,
    positiveColor,
    negativeColor,
    relegationColor,
    itemColor,
    items,
  } = tableData;

  // Sort the items based on their tablePlace
  const sortedItems = [...items].sort((a, b) => a.tablePlace - b.tablePlace);

  return (
    <div className="mb-4">
      {/* Apply itemColor to the table name */}
      <h1 className="text-xl font-bold mb-4">{name}</h1>
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            {/* Apply itemColor to the table headers */}
            <th className="border-b py-2 text-left">Place</th>
            <th className="border-b py-2 text-left">Name</th>
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item, index) => {
            // Determine background color based on the item's position
            let backgroundColor = 'white'; // Default background color

            if (index === 0) {
              backgroundColor = winnerColor;
            } else if (index < positiveAfterSeasonTeams) {
              backgroundColor = positiveColor;
            } else if (
              index >= sortedItems.length - numberOfRelegations - negativeAfterSeasonTeams &&
              index < sortedItems.length - numberOfRelegations
            ) {
              backgroundColor = negativeColor;
            } else if (index >= sortedItems.length - numberOfRelegations) {
              backgroundColor = relegationColor;
            }

            return (
              <tr key={item.tablePlace} style={{ backgroundColor }}>
                {/* Apply itemColor to the table data cells */}
                <td className="border-b py-2 px-4" style={{ color: itemColor }}>
                  {item.tablePlace}
                </td>
                <td className="border-b py-2 px-4" style={{ color: itemColor }}>
                  {item.name}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTable;
