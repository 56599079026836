import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../../Contexts/ApiProvider';
import { FaArrowLeft } from 'react-icons/fa';
import { useJwt } from '../../../../../Contexts/JwtProvider';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MainFooter from '../../../../Partials/Footer/MainFooter';
import MainHeader from '../../../../Partials/Header/MainHeader';
import { Country } from '../../../../../types/enums';
import { RegisterTeamRequest } from '../../../../../types/apimodels';

interface FormValues {
  name: string;
  englishName: string;
  country: string;
  imageUrl: string;
  website: string;
}

const AdminRegisterTeamPage: React.FC = () => {
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  if (!apiContext) {
    throw new Error('ApiContext must be used within an ApiProvider');
  }

  const { registerTeamAsync } = apiContext;

  const [resultMessage, setResultMessage] = useState<string | null>(null);

  // Get numeric values of Country enum and convert to strings
  const countryValues = Object.values(Country)
    .filter(value => typeof value === 'number')
    .map(value => value.toString());

  const validationSchema = Yup.object({
    name: Yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    englishName: Yup.string().min(2, 'Must be at least 2 characters').notRequired(),
    country: Yup.string().oneOf(countryValues, 'Invalid country').required('Required'),
    imageUrl: Yup.string().url('Invalid URL format').notRequired(),
    website: Yup.string().url('Invalid URL format').notRequired(),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      englishName: '',
      country: '',
      imageUrl: '',
      website: '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const request: RegisterTeamRequest = {
        ...values,
        country: parseInt(values.country, 10) as Country,
      };
      const success = await registerTeamAsync(request);
      if (success) {
        setResultMessage('Team registration was successful');
        formik.resetForm();
      } else {
        setResultMessage('Team registration failed');
      }
    },
  });

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 py-8">
        <div className="flex items-center mb-6">
          <button className="text-primary hover:text-primary-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} />
          </button>
          <h1 className="text-3xl font-bold text-primary text-center flex-grow">Register Team</h1>
        </div>
        <form onSubmit={formik.handleSubmit} className="bg-white shadow-md rounded-lg p-6 w-full max-w-lg mx-auto">
          {/* Team Name */}
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              Team Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              placeholder="Enter team name"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>
            )}
          </div>
          {/* English Name */}
          <div className="mb-4">
            <label htmlFor="englishName" className="block text-sm font-medium text-gray-700">
              English Name
            </label>
            <input
              type="text"
              name="englishName"
              placeholder="Enter English name (optional)"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.englishName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.englishName && formik.errors.englishName && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.englishName}</div>
            )}
          </div>
          {/* Country */}
          <div className="mb-4">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Country <span className="text-red-500">*</span>
            </label>
            <select
              name="country"
              className="mt-1 block w-full border border-gray-300 bg-white rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}>
              <option value="" label="Select country" />
              {Object.keys(Country)
                .filter(key => isNaN(Number(key))) // Exclude numeric keys
                .map(key => {
                  const value = Country[key as keyof typeof Country] as number;
                  return (
                    <option key={key} value={value.toString()}>
                      {key.replace(/([A-Z])/g, ' $1').trim()}
                    </option>
                  );
                })}
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.country}</div>
            )}
          </div>
          {/* Image URL */}
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700">
              Image URL
            </label>
            <input
              type="url"
              name="imageUrl"
              placeholder="Enter image URL (optional)"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.imageUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.imageUrl && formik.errors.imageUrl && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.imageUrl}</div>
            )}
          </div>
          {/* Website */}
          <div className="mb-6">
            <label htmlFor="website" className="block text-sm font-medium text-gray-700">
              Website
            </label>
            <input
              type="url"
              name="website"
              placeholder="Enter website URL (optional)"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring-primary focus:border-primary sm:text-sm"
              value={formik.values.website}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.website && formik.errors.website && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.website}</div>
            )}
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
            Register Team
          </button>
        </form>
        {/* Result Message */}
        {resultMessage && <div className="mt-6 text-center text-lg font-semibold text-primary">{resultMessage}</div>}
      </div>
      <MainFooter />
    </div>
  );
};

export default AdminRegisterTeamPage;
