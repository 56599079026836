import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../../Contexts/ApiProvider';
import { useJwt } from '../../../../Contexts/JwtProvider';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';
import { RegisterSimpleTableRequest } from '../../../../types/apimodels';
import SimpleTableForm from '../../../Partials/SimpleTable/SimpleTableForm';

const NewSimpleTablePage: React.FC = () => {
  const apiContext = useContext(ApiContext);
  const { role, handlerId } = useJwt();
  const navigate = useNavigate();

  // Placeholder for missing handlerId
  const placeholderId = 'test-test-test-test-test';

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<string>('');

  useEffect(() => {
    if (role === null) {
      navigate('/notauthorized');
    }
  }, [role, navigate]);

  const initialFormData: RegisterSimpleTableRequest = {
    handlerId: handlerId || placeholderId,
    name: '',
    numberOfTeams: 10,
    positiveAfterSeasonTeams: 0,
    negativeAfterSeasonTeams: 0,
    numberOfRelegations: 0,
    items: Array.from({ length: 10 }, (_, i) => ({
      name: '',
      tablePlace: i + 1,
    })),
    winnerColor: '#0f1990',
    positiveColor: '#2667b0',
    negativeColor: '#c55258',
    relegationColor: '#5d191c',
    itemColor: '#000000',
    isPublicTable: false,
  };

  const handleSubmit = async (formData: RegisterSimpleTableRequest) => {
    setSubmissionError('');

    if (apiContext && formData.handlerId !== placeholderId) {
      setIsSubmitting(true);
      try {
        const success = await apiContext.registerSimpleTableAsync(formData);
        setIsSubmitting(false);
        if (success) {
          navigate('/my-account/tables');
        } else {
          setSubmissionError('Failed to create table. Please try again later.');
        }
      } catch (error) {
        setIsSubmitting(false);
        setSubmissionError('Failed to create table. Please try again later.');
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-bold mb-6">Create New Table</h1>
        <SimpleTableForm
          initialFormData={initialFormData}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          submissionError={submissionError}
          isPublic={false}
        />
      </div>
      <MainFooter />
    </div>
  );
};

export default NewSimpleTablePage;
