export const commonColors = [
  { name: 'Red', hex: '#FF0000' },
  { name: 'Blue', hex: '#0000FF' },
  { name: 'Dark Blue', hex: '#0f1990' },
  { name: 'Green', hex: '#008000' },
  { name: 'Yellow', hex: '#FFFF00' },
  { name: 'Orange', hex: '#FFA500' },
  { name: 'Purple', hex: '#800080' },
  { name: 'Pink', hex: '#FFC0CB' },
  { name: 'Brown', hex: '#A52A2A' },
  { name: 'Black', hex: '#000000' },
  { name: 'White', hex: '#FFFFFF' },
  { name: 'Gray', hex: '#808080' },
  { name: 'Dark Gray', hex: '#A9A9A9' },
  { name: 'Light Gray', hex: '#D3D3D3' },
  { name: 'Cyan', hex: '#00FFFF' },
  { name: 'Magenta', hex: '#FF00FF' },
  { name: 'Lime', hex: '#00FF00' },
  { name: 'Maroon', hex: '#800000' },
  { name: 'Navy', hex: '#000080' },
  { name: 'Olive', hex: '#808000' },
  { name: 'Teal', hex: '#008080' },
  { name: 'Turquoise', hex: '#40E0D0' },
  { name: 'Violet', hex: '#EE82EE' },
  { name: 'Indigo', hex: '#4B0082' },
  { name: 'Gold', hex: '#FFD700' },
  { name: 'Silver', hex: '#C0C0C0' },
  { name: 'Beige', hex: '#F5F5DC' },
  { name: 'Coral', hex: '#FF7F50' },
  { name: 'Crimson', hex: '#DC143C' },
  { name: 'Khaki', hex: '#F0E68C' },
  { name: 'Lavender', hex: '#E6E6FA' },
  { name: 'Orchid', hex: '#DA70D6' },
  { name: 'Plum', hex: '#DDA0DD' },
  { name: 'Salmon', hex: '#FA8072' },
  { name: 'Sea Green', hex: '#2E8B57' },
  { name: 'Sienna', hex: '#A0522D' },
  { name: 'Sky Blue', hex: '#87CEEB' },
  { name: 'Slate Blue', hex: '#6A5ACD' },
  { name: 'Tomato', hex: '#FF6347' },
  { name: 'Dark Red', hex: '#8B0000' },
  { name: 'Winner Blue', hex: '#0f1990' },
  { name: 'Positive Blue', hex: '#2667b0' },
  { name: 'Negative Red', hex: '#c55258' },
  { name: 'Relegation Maroon', hex: '#5d191c' },
];
