import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NotAuthorizedPage: React.FC = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState<number>(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate('/');
    }, 10000);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <div className="text-red-600 text-6xl mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 mx-auto"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M12 8h.01M12 2a10 10 0 11-10 10A10 10 0 0112 2z"
            />
          </svg>
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Not Authorized</h1>
        <p className="text-lg text-gray-600 mb-6">
          You do not have permission to view this page. Returning to homepage in {countdown} seconds...
        </p>
        <button
          className="bg-primary text-white py-2 px-4 rounded-full font-semibold hover:bg-primary-dark transition-colors duration-300"
          onClick={() => navigate('/')}>
          Go to Homepage Now
        </button>
      </div>
    </div>
  );
};

export default NotAuthorizedPage;
