import React, { useState, useContext } from 'react';
import { useJwt } from '../../../Contexts/JwtProvider';
import { useNavigate } from 'react-router-dom';
import { ApiContext } from '../../../Contexts/ApiProvider';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

const MainHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const { role } = useJwt();
  const apiContext = useContext(ApiContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (apiContext) {
      const result = await apiContext.logoutAsync();
      if (result) {
        navigate('/');
      } else {
        console.log('Logout failed');
      }
    }
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
    setOpenDropdown(null);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setOpenDropdown(null);
  };

  const toggleDropdown = (dropdown: string) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdown);
    }
  };

  const menuItems = [
    {
      title: 'Company',
      links: ['About', 'Our team', 'Career', 'Partners', 'News', 'Contact'],
    },
    {
      title: 'Our Product',
      links: ['Leagues', 'Leaderboards', 'Roadmap'],
    },
    {
      title: 'Tables',
      links: ['Official tables', 'Create table'],
    },
  ];

  return (
    <header className="bg-primary text-white fixed w-full z-50 shadow-md">
      <div className="container mx-auto flex justify-between items-center px-4 py-3">
        {/* Logo or Brand Name */}
        <div className="flex items-center">
          <button
            className="text-2xl font-bold text-white hover:text-accent transition-colors duration-300 focus:outline-none"
            onClick={() => handleNavigate('/')}>
            Table Top Tippers
          </button>
        </div>
        {/* Desktop Menu */}
        <nav className="hidden md:flex space-x-6 items-center">
          {menuItems.map(item => (
            <div key={item.title} className="relative">
              <button
                className="hover:text-accent transition-colors duration-300 focus:outline-none flex items-center"
                onClick={() => toggleDropdown(item.title)}>
                {item.title}
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-200 ${
                    openDropdown === item.title ? 'rotate-180' : ''
                  }`}
                  size={12}
                />
              </button>
              {/* Dropdown Menu */}
              {openDropdown === item.title && (
                <div className="absolute left-0 mt-2 w-48 bg-primary text-white rounded-md shadow-lg z-10">
                  <ul className="py-2">
                    {item.links.map((link, index) => (
                      <li key={index}>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate(`/${link.toLowerCase().replace(' ', '-')}`)}>
                          {link}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
          {/* Account Menu */}
          <div className="relative">
            <button
              className="hover:text-accent transition-colors duration-300 focus:outline-none flex items-center"
              onClick={() => toggleDropdown('Account')}>
              Account
              <FaChevronDown
                className={`ml-1 transform transition-transform duration-200 ${
                  openDropdown === 'Account' ? 'rotate-180' : ''
                }`}
                size={12}
              />
            </button>
            {/* Dropdown Menu */}
            {openDropdown === 'Account' && (
              <div className="absolute right-0 mt-2 w-48 bg-primary text-white rounded-md shadow-lg z-10">
                <ul className="py-2">
                  {role === 'admin' && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate('/my-account')}>
                          My Account
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate('/admin')}>
                          Admin
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={handleLogout}>
                          Log Out
                        </button>
                      </li>
                    </>
                  )}
                  {role === 'user' && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate('/my-account')}>
                          My Account
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={handleLogout}>
                          Log Out
                        </button>
                      </li>
                    </>
                  )}
                  {!role && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate('/login')}>
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left px-4 py-2 hover:bg-accent hover:text-primary transition-colors duration-300"
                          onClick={() => handleNavigate('/register')}>
                          Register
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            )}
          </div>
        </nav>
        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button className="text-white focus:outline-none" onClick={toggleMenu} aria-label="Toggle menu">
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden bg-primary">
          <ul className="flex flex-col space-y-4 px-4 py-4">
            {menuItems.map(item => (
              <li key={item.title}>
                <button
                  className="flex justify-between items-center w-full text-left text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                  onClick={() => toggleDropdown(item.title)}>
                  {item.title}
                  <FaChevronDown
                    className={`ml-1 transform transition-transform duration-200 ${
                      openDropdown === item.title ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                {/* Collapsible Menu */}
                {openDropdown === item.title && (
                  <ul className="mt-2 space-y-2">
                    {item.links.map((link, index) => (
                      <li key={index}>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate(`/${link.toLowerCase().replace(' ', '-')}`)}>
                          {link}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            {/* Account Menu */}
            <li>
              <button
                className="flex justify-between items-center w-full text-left text-white hover:text-accent transition-colors duration-300 focus:outline-none"
                onClick={() => toggleDropdown('Account')}>
                Account
                <FaChevronDown
                  className={`ml-1 transform transition-transform duration-200 ${
                    openDropdown === 'Account' ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {/* Collapsible Menu */}
              {openDropdown === 'Account' && (
                <ul className="mt-2 space-y-2">
                  {role === 'admin' && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate('/my-account')}>
                          My Account
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate('/admin')}>
                          Admin
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={handleLogout}>
                          Log Out
                        </button>
                      </li>
                    </>
                  )}
                  {role === 'user' && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate('/my-account')}>
                          My Account
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={handleLogout}>
                          Log Out
                        </button>
                      </li>
                    </>
                  )}
                  {!role && (
                    <>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate('/login')}>
                          Login
                        </button>
                      </li>
                      <li>
                        <button
                          className="block w-full text-left text-white hover:text-accent transition-colors duration-300"
                          onClick={() => handleNavigate('/register')}>
                          Register
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default MainHeader;
