import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const LeaguesPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 p-14 container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h1 className="text-4xl font-bold text-primary mb-6">Available Leagues</h1>
          <p className="text-lg text-neutral mb-6">Currently, there are no leagues available.</p>
          <h2 className="text-3xl font-semibold text-primary mb-4">Coming Soon</h2>
          <div className="text-left max-w-lg mx-auto">
            {[
              'Allsvenskan - Coming November 2024',
              'Superettan - Coming November 2024',
              'Advanced Allsvenskan - Coming February 2025',
              'Advanced Superettan - Coming February 2025',
              'Bundesliga - Coming May 2025',
              'Premier League - Coming May 2025',
              'Serie A - Coming May 2025',
              'Hockey Leagues - Coming August 2025',
            ].map((league, index) => (
              <p key={index} className="text-lg text-neutral mb-2">
                {league}
              </p>
            ))}
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default LeaguesPage;
