import React from 'react';
import MainHeader from '../../../Partials/Header/MainHeader';
import MainFooter from '../../../Partials/Footer/MainFooter';

const LeaderboardsPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <MainHeader />
      <div className="flex-1 p-14 container mx-auto">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h1 className="text-4xl font-bold text-primary mb-6">Leaderboards</h1>
          <p className="text-lg text-neutral mb-6">
            Here we will show public profiles with the highest score for every league and gamemode to keep track of who
            is the global leader/winner.
          </p>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default LeaderboardsPage;
