import React, { useState, useEffect } from 'react';
import SimpleTable, { SimpleTableProps } from '../../Partials/SimpleTable/SimpleTable';
import { commonColors } from '../../../types/commoncolors';
import { RegisterSimpleTableRequest } from '../../../types/apimodels';

interface SimpleTableFormProps {
  initialFormData: RegisterSimpleTableRequest;
  onSubmit: (formData: RegisterSimpleTableRequest, email?: string) => Promise<void>;
  isSubmitting: boolean;
  submissionError: string;
  isPublic: boolean;
}

const SimpleTableForm: React.FC<SimpleTableFormProps> = ({
  initialFormData,
  onSubmit,
  isSubmitting,
  submissionError,
  isPublic,
}) => {
  const [formData, setFormData] = useState<RegisterSimpleTableRequest>(initialFormData);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    // Initialize items if empty
    if (formData.items.length === 0) {
      setFormData(prev => ({
        ...prev,
        items: Array.from({ length: formData.numberOfTeams }, (_, i) => ({
          name: '',
          tablePlace: i + 1,
        })),
      }));
    }
  }, [formData.numberOfTeams]);

  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleTeamsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const numberOfTeams = parseInt(e.target.value, 10);
    setFormData(prev => ({
      ...prev,
      numberOfTeams,
      items: Array.from({ length: numberOfTeams }, (_, i) => ({
        name: '',
        tablePlace: i + 1,
      })),
    }));
  };

  const handleItemChange = (index: number, field: string, value: string) => {
    const updatedItems = [...formData.items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setFormData(prev => ({
      ...prev,
      items: updatedItems,
    }));
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const numberValue = parseInt(value, 10);

    setFormData(prev => {
      let newValue = numberValue;

      // Ensure total of positive, negative, and relegations does not exceed number of teams
      if (
        name === 'positiveAfterSeasonTeams' ||
        name === 'negativeAfterSeasonTeams' ||
        name === 'numberOfRelegations'
      ) {
        const otherValues =
          name === 'positiveAfterSeasonTeams'
            ? prev.negativeAfterSeasonTeams + prev.numberOfRelegations
            : name === 'negativeAfterSeasonTeams'
              ? prev.positiveAfterSeasonTeams + prev.numberOfRelegations
              : prev.positiveAfterSeasonTeams + prev.negativeAfterSeasonTeams;

        if (numberValue + otherValues > prev.numberOfTeams) {
          newValue = prev.numberOfTeams - otherValues;
        }
      }

      return {
        ...prev,
        [name]: newValue,
      };
    });
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      isPublicTable: e.target.checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isPublic) {
      // Validate email for public form
      setEmailError('');
      if (!email || !validateEmail(email)) {
        setEmailError('Please enter a valid email address.');
        return;
      }
      await onSubmit(formData, email);
    } else {
      await onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Basic Info */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Table Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Table Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm"
          />
        </div>
        {/* Number of Teams */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Number of Teams</label>
          <select
            name="numberOfTeams"
            value={formData.numberOfTeams}
            onChange={handleTeamsChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm">
            {Array.from({ length: 39 }, (_, i) => (
              <option key={i + 2} value={i + 2}>
                {i + 2}
              </option>
            ))}
          </select>
        </div>
        {/* Public Table Toggle or Notice */}
        {isPublic ? (
          <div className="md:col-span-1">
            <p className="text-sm text-gray-700 mt-6">
              This table will be <span className="font-semibold">public</span>. You need to be logged in to create
              private tables.
            </p>
          </div>
        ) : (
          <div className="flex items-center mt-4 md:mt-0">
            <label htmlFor="isPublicTable" className="block text-sm font-medium text-gray-700 mr-4">
              Public Table
            </label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="isPublicTable"
                checked={formData.isPublicTable}
                onChange={handleToggleChange}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:bg-blue-600">
                <span
                  className={`absolute left-0 top-0 h-6 w-6 bg-white border border-gray-300 rounded-full transition-transform duration-200 transform ${
                    formData.isPublicTable ? 'translate-x-full' : ''
                  }`}></span>
              </div>
            </label>
          </div>
        )}
      </div>

      {/* Season Information */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Positive After Season Teams */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Positive Teams</label>
          <select
            name="positiveAfterSeasonTeams"
            value={formData.positiveAfterSeasonTeams}
            onChange={handleNumberInputChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm">
            {Array.from(
              {
                length: formData.numberOfTeams - formData.negativeAfterSeasonTeams - formData.numberOfRelegations + 1,
              },
              (_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              )
            )}
          </select>
        </div>

        {/* Negative After Season Teams */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Negative Teams</label>
          <select
            name="negativeAfterSeasonTeams"
            value={formData.negativeAfterSeasonTeams}
            onChange={handleNumberInputChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm">
            {Array.from(
              {
                length: formData.numberOfTeams - formData.positiveAfterSeasonTeams - formData.numberOfRelegations + 1,
              },
              (_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              )
            )}
          </select>
        </div>

        {/* Number of Relegations */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Relegations</label>
          <select
            name="numberOfRelegations"
            value={formData.numberOfRelegations}
            onChange={handleNumberInputChange}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm">
            {Array.from(
              {
                length:
                  formData.numberOfTeams - formData.positiveAfterSeasonTeams - formData.negativeAfterSeasonTeams + 1,
              },
              (_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              )
            )}
          </select>
        </div>
      </div>

      {/* Team Items */}
      <div>
        <h2 className="text-lg font-medium text-gray-900">Team Names</h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-4 mt-2">
          {formData.items.map((item, index) => (
            <div key={index}>
              <label className="block text-xs font-medium text-gray-700">Team {index + 1}</label>
              <input
                type="text"
                value={item.name}
                onChange={e => handleItemChange(index, 'name', e.target.value)}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-xs"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Color Selection */}
      <div>
        <h2 className="text-lg font-medium text-gray-900">Color Settings</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-4 mt-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">Winner Color</label>
            <select
              name="winnerColor"
              value={formData.winnerColor}
              onChange={handleColorChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm bg-gray-100">
              {commonColors.map(color => (
                <option key={color.hex} value={color.hex}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Positive Color</label>
            <select
              name="positiveColor"
              value={formData.positiveColor}
              onChange={handleColorChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm bg-gray-100">
              {commonColors.map(color => (
                <option key={color.hex} value={color.hex}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Negative Color</label>
            <select
              name="negativeColor"
              value={formData.negativeColor}
              onChange={handleColorChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm bg-gray-100">
              {commonColors.map(color => (
                <option key={color.hex} value={color.hex}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Relegation Color</label>
            <select
              name="relegationColor"
              value={formData.relegationColor}
              onChange={handleColorChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm bg-gray-100">
              {commonColors.map(color => (
                <option key={color.hex} value={color.hex}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Item Text Color</label>
            <select
              name="itemColor"
              value={formData.itemColor}
              onChange={handleColorChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm bg-gray-100">
              {commonColors.map(color => (
                <option key={color.hex} value={color.hex}>
                  {color.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Real-time Preview and Submit Section */}
      <div className="flex flex-col md:flex-row gap-8 mt-10">
        {/* Real-time Preview */}
        <div className="flex-1 md:max-w-2xl">
          <h2 className="text-xl font-bold mb-4">Preview</h2>
          <SimpleTable tableData={formData as SimpleTableProps} />
        </div>
        {/* Email Input and Submit Button */}
        <div className="md:w-1/3">
          <div className="space-y-4">
            {isPublic && (
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-sm"
                  placeholder="Enter your email"
                />
                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
              </div>
            )}
            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                disabled={isSubmitting}>
                {isSubmitting ? (
                  // Spinner
                  <svg
                    className="animate-spin h-5 w-5 text-white mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                ) : isPublic ? (
                  'Submit'
                ) : (
                  'Create Table'
                )}
              </button>
              {submissionError && <p className="text-red-500 text-sm mt-2">{submissionError}</p>}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SimpleTableForm;
